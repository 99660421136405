import { Transition, TransitionChild } from "@headlessui/react";
import { Fragment } from "react";
import { clsx } from "../../../modules/Utilkit/Utilkit";

const Popup = ({ posX, posY, content, show }) => {
  return (
    <Popcraft
      show={ show }
      className={ clsx(
        "-translate-x-1/2 min-w-[220px] bg-white rounded-sm shadow-lg border border-gray-300",
        posY > window.innerHeight * 0.75 ? "-translate-y-full" : "",
      ) }
      posX={ posX }
      posY={ posY > window.innerHeight * 0.75 ? posY - 5 : posY + 20 }
    >
      { content }
    </Popcraft>
  );
};

const Popcraft = ({ show, children, className, posX, posY }) => {
  return (
    <Transition
      show={ show }
      className="fixed z-50"
      style={ { top: posY, left: posX } }
    >
      <TransitionChild
        as={ Fragment }
        enter="transition ease-out duration-75 origin-top"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75 origin-top"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div className={ className ?? "min-w-[220px] bg-white rounded-sm shadow-lg border border-gray-300" } >
          { children }
        </div>
      </TransitionChild>
    </Transition>
  );
};


export default Popup;