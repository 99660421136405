import { useEffect, useState } from 'react';
import secureLocalStorage from 'react-secure-storage';
import config from '../config';
import { addStore, getStore, useStash } from './Stash/Stash';

export default async function req(url, data = {}) {
  const token = secureLocalStorage.getItem('token');

  const res = await fetch(config.backend, {
    method: 'POST',
    body: JSON.stringify({ url, token, data })
  }).then(res => {
    if (res.status === 200) {
      return { code: 200, data: res.json() ?? null };
    } else {
      return { code: res.status, error: res.statusText };
    }
  }).catch(err => ({ code: 600, error: 'uknown' }));

  if (res.code === 200) {
    if (res.data) {
      const body = await res.data.then(res => res).catch(err => ({ error: 'json' }));
      if (body.status === "success") {
        return { success: true, data: body.body };
      } else if (body.status === "error") {
        return { error: body.error };
      } else {
        return { error: 'status' };
      }
    } else {
      return { error: 'response' };
    }
  } else if (res.code >= 100 && res.code < 200) {
    return { error: 'info' };
  } else if (res.code >= 300 && res.code < 400) {
    return { error: 'redirect' };
  } else if (res.code >= 400 && res.code < 500) {
    return { error: 'client' };
  } else if (res.code >= 500 && res.code < 600) {
    return { error: 'server' };
  } else {
    return { error: 'unknown' };
  }
}

export function useReq({ url, handleError, handleSelect }, deps = []) {
  const [ data, setData ] = useState(getStore('req-data-' + url) ?? []);

  useEffect(() => {
    const fetch = async () => {
      if (handleSelect) {
        const res = await handleSelect();

        if (res) {
          if (res.success) {
            setData(res.data);
            addStore('req-data-' + url, res.data);
          } else {
            handleError(res.error);
          }
        }
      } else {
        const res = await req('get-' + url);

        if (res.success) {
          setData(res.data);
          addStore('req-data-' + url, res.data);
        } else {
          handleError(res.error);
        }
      }
    };
    fetch();
  }, [ ...deps ]);

  const insertData = async (targetData) => {
    const res = await req('insert-' + url, targetData);

    if (res.success) {
      const newdata = [ ...data, res.data ];
      addStore('req-data-' + url, newdata);
      setData(newdata);
    } else {
      handleError(res.error);
    }
  };
  const updateData = async (targetID, targetData) => {
    const res = await req('update-' + url, { targetID, targetData });

    if (res.success) {
      const newdata = data.map(item => item.id === targetID ? targetData : item);
      addStore('req-data-' + url, newdata);
      setData(newdata);
    } else {
      handleError(res.error);
    }
  };
  const deleteData = (targetID) => {
    const res = req('delete-' + url, { id: targetID });

    if (res.success) {
      const newdata = data.filter(item => item.id !== targetID);
      addStore('req-data-' + url, newdata);
      setData(newdata);
    } else {
      handleError(res.error);
    }
  };

  return [ data, insertData, updateData, deleteData ];
}

export function useRequest(url, { onSelect, onInsert, onUpdate, onDelete, onError, payload }, deps = []) {
  const [ data, setData ] = useStash('data-' + url, []);

  useEffect(() => {
    const fetch = async () => {
      if (onSelect) {
        const res = await req(url, { action: 'select', ...payload });

        if (res) {
          if (res.success) {
            setData(onSelect({ response: res.data }));
          } else {
            onError(res.error);
          }
        }
      } else {
        const res = await req(url, { action: 'select', ...payload });

        if (res.success) {
          setData(res.data);
        } else {
          onError(res.error);
        }
      }
    };

    fetch();
  }, [ ...deps ]);

  const insert = async (targetData) => {
    const res = await req(url, { ...targetData, action: 'insert', ...payload });

    if (res.success) {
      setData(onInsert({ data, response: res.data }));
    } else {
      onError(res.error);
    }
  };

  const update = async (targetID, targetData) => {
    const res = await req(url, { id: targetID, ...targetData, action: 'update', ...payload });

    if (res.success) {
      setData(onUpdate({ data, id: targetID, response: res.data }));
    } else {
      onError(res.error);
    }
  };

  const remove = (targetID) => {
    const res = req(url, { id: targetID, action: 'update', ...payload });

    if (res.success) {
      setData(onDelete({ data, id: targetID }));
    } else {
      onError(res.error);
    }
  };

  return [ data, insert, update, remove ];
}