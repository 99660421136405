import { useEffect, useMemo, useRef, useState } from "react";
import { useWeGanttConsumer } from "..";
import Moment from "../../../modules/Moment/Moment";
import { clsx } from "../../../modules/Utilkit/Utilkit";
import { Vector2D } from "../../../modules/Vecto/Vecto";
import Milestone from "./Milestone";
import Popup from "./Popup";
import Split from "./Split";
import Timesheet from "./Timesheet";


const Task = ({ task }) => {
  const { days, workingHours, cellWidth, cellHeight, fontSize, holidays, onMove, onResize, onTaskClick, onTaskDoubleClick,
    onTaskContextMenu, onTaskHover, onEmptyCellClick, TaskCell, range, widthOfGantt, viewMode } = useWeGanttConsumer();

  const bar = useRef(null);
  const row = useRef(null);
  const durationInDays = task.duration / workingHours % 1 == 0 ? task.duration / workingHours : Math.floor(task.duration / workingHours);

  const [ popup, setPopup ] = useState({ event: undefined, x: 0, y: 0, content: undefined, show: false });

  const divider = viewMode === 'weeks' ? 7 : 1;
  const durationDays = task.duration / workingHours;
  const startPoint = Moment(range.from).duration(task.start);
  const weeksCovered = Math.ceil((durationDays + (startPoint % divider)) / divider);
  
  let left, width;
  if (viewMode === 'weeks') {
    left = (Moment(range.from).duration(task.start, { size: 'weeks' }) - 2) * cellWidth;
    width = Moment(task.start).duration(task.end, { size: 'weeks' }) * cellWidth;
  } else {
    left = (Math.floor(startPoint / divider) * cellWidth);
    width = weeksCovered * cellWidth;
  }

  if(task.type === 'milestone') {
    width = cellWidth;
  }

  /** Process Task Label */
  const taskLabel = useMemo(() => {
    if (!task.name) return "";
    if (task.type === 'milestone' && !task.date) return "";
    if ([ 'task', 'project' ].includes(task.type) && (!task.start || !task.end)) return "";

    if ((task.name?.length * (fontSize / 2)) + ((fontSize / 2) * 8) > width) {
      return (
        <div className="absolute translate-x-full text-black select-none pointer-events-none -right-1">
          { TaskCell ? (
            <TaskCell task={ task } />
          ) : (
            task.name
          ) }
        </div>
      );
    } else {
      return TaskCell ? (
        <TaskCell task={ task } />
      ) : (
        task.name
      );
    }
  }, [ task, fontSize, width ]);

  /** Task Events */
  let isMove = useRef(false);
  let isResize = useRef(false);
  let clickPos = useRef(0);

  useEffect(() => {
    const handleMouseUp = (e) => {
      if (isMove.current) {
        e.preventDefault();
        isMove.current = false;
        try {

          const position = Math.floor(bar.current.offsetLeft / cellWidth);
          let newStartMoment = new Moment(range.from).add(position);

          const newStart = newStartMoment.available(holidays, "prev");
          const newEnd = Moment(newStart).end(task.duration / workingHours, holidays);

          console.log(task.end, newEnd, task.duration);

          if (newStart !== task.start && onMove) {
            onMove({ ...task, start: newStart, end: newEnd });
          } else {
            handleTaskClick(e);
          }
        } catch (e) {
          console.error(e);
        }
        bar.current.style.left = `${left}px`;
      } else if (isResize.current) {
        e.preventDefault();
        isResize.current = false;

        try {
          const size = Math.floor(bar.current.offsetWidth / cellWidth) + 1;
          const end = new Moment(task.start).end(size);

          const newEnd = new Moment(end).available(holidays, "next");
          const newLength = new Moment(task.start).duration(newEnd, holidays) + 1;

          if (newLength !== durationInDays && onResize) {
            onResize({ ...task, duration: newLength * workingHours, end: newEnd });
            bar.current.style.width = `${newLength * cellWidth}px`;
          } else {
            bar.current.style.width = `${width}px`;
          }
        } catch (e) {
          bar.current.style.width = `${width}px`;
        }
      }
    };

    const handleMouseMove = (e) => {
      if (isMove.current) {
        const newX = e.clientX - clickPos.current;
        bar.current.style.left = `${newX}px`;
      } else if (isResize.current) {
        const newWidth = e.clientX - clickPos.current - bar.current.offsetLeft + width;
        if (newWidth > cellWidth * 3 / 4) {
          bar.current.style.width = `${newWidth}px`;
        } else {
          bar.current.style.width = `${cellWidth * 3 / 4}px`;
        }
      }
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [ width, left, days, task ]);

  const handleEmptyCellClick = (event) => {
    if (event.target !== event.currentTarget) return;
    const pos = Math.floor((event.clientX - event.currentTarget.getBoundingClientRect().left) / cellWidth);
    const selectedDate = days[ pos ].date;
    if (!onEmptyCellClick) return;
    onEmptyCellClick({ ...task, selectedDate, event });
  };

  const handleTaskClick = (event) => {
    if (!onTaskClick || event.button !== 0) return;
    onTaskClick(task, event);
  };

  const handleTaskDoubleClick = (event) => {
    if (!onTaskDoubleClick || event.button !== 0) return;
    onTaskDoubleClick({ task, event });
  };

  const handleTaskContextMenu = (event) => {
    if (!onTaskContextMenu) return;
    event.preventDefault();
    const position = new Vector2D().point(event, row.current).div(cellWidth).floor();
    const selectedDate = new Moment(range.from).add(position.x()).value();
    onTaskContextMenu({ task: { ...task, selectedDate }, event });
  };

  const handleTaskHover = (event) => {
    if (!onTaskHover) return;
    const content = onTaskHover({ task, event });
    if (!content) return;
    setPopup({ x: event.clientX, y: event.clientY, show: true, content });
  };

  console.log('Task', task.name, task.label, task.status);

  return (
    <>
      <Popup event={ popup.event } posX={ popup.x } posY={ popup.y } content={ popup.content } show={ popup.show } />

      <div
        ref={ row }
        className="relative overflow-x-hidden z-[25]"
        style={ { height: cellHeight, width: widthOfGantt } }
        onMouseDown={ handleEmptyCellClick }
      >
        { /* TIMESHEETS */ }
        { task.timesheets && task.timesheets.map((timesheet, index) => (
          <Timesheet
            key={ task.id + timesheet.id }
            task={ task }
            timesheet={ timesheet }
            onClosePopup={ () => setPopup({ ...popup, show: false }) }
            onShowPopup={ ({ event, content }) => setPopup({ x: event.clientX, y: event.clientY, content, show: true }) }
          />
        )) }

        { task.dates?.length > 0 ? (
          task.dates?.map((splitTask, index) => (
            // SPLIT TASK
            <Split
              key={ task.id + index }
              split={ splitTask }
              rowRef={ row }
              task={ task }
            />
          ))
        ) : task.type === 'milestone' ? (
          // MILESTONE
          <Milestone
            task={ task }
            rowRef={ row }
            onClosePopup={ () => setPopup({ ...popup, show: false }) }
            onShowPopup={ ({ event, content }) => setPopup({ x: event.clientX, y: event.clientY, content, show: true }) }
          />
        ) : (
          // TASK
          <div ref={ bar } className="h-full absolute flex select-none py-0.5" style={ { top: 0, left, width } } >
            <div
              onMouseEnter={ (e) => e.currentTarget.style.filter = 'brightness(120%)' }
              onMouseLeave={ (e) => e.currentTarget.style.filter = 'brightness(100%)' }
              style={ {
                fontSize: fontSize * 0.8,
                backgroundColor: task.color || '#3b82f6',
                opacity: task.status === 'completed' ? 0.4 : task.status === 'hidden' ? 0 : 1,
                display: task.hidden && 'none'
              } }
              className={ clsx(
                "w-full duration-75 z-10 flex text-white",
                task.type == 'task' && task.isDraggable && viewMode === 'days' && "cursor-pointer",
                task.status === 'dashed' ? "border border-dashed border-[#888]" : "border border-[#AAA]"
              ) }
            >
              { task.isResizable && task.type == 'task' && viewMode === 'days' && (
                <div className="w-1 select-none" />
              ) }

              <div
                className="flex-1 flex justify-center items-center select-none"
                style={ { overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" } }
                onMouseDown={ (e) => {
                  if (e.button === 0) {
                    if (task.isDraggable && task.type == 'task' && viewMode === 'days' && task.start > range.from && task.end < range.to) {
                      clickPos.current = e.clientX - bar.current.offsetLeft;
                      isMove.current = true;
                    } else {
                      handleTaskClick(e);
                    }
                  }
                } }
                onContextMenu={ handleTaskContextMenu }
                onDoubleClick={ handleTaskDoubleClick }
                onMouseEnter={ handleTaskHover }
                onMouseLeave={ () => setPopup({ ...popup, show: false }) }
              >
                { taskLabel }
              </div>
              { task.isResizable && task.type == 'task' && viewMode === 'days' && (
                <div
                  className={ clsx("w-1", task.type == 'task' && task.isDraggable && "cursor-ew-resize") }
                  onMouseDown={ (e) => {
                    e.preventDefault();
                    clickPos.current = e.clientX - bar.current.offsetLeft;
                    isResize.current = true;
                  } }
                />
              ) }
            </div>
          </div>
        ) }
      </div>
    </>
  );
};

export default Task;