import { useEffect, useState } from "react";
import { useNavigator } from "../../../components/Navigator";
import * as session from "../../../../modules/Stash/Stash";
import req from "../../../../modules/Request";
import { useNotifyContext } from "../../../components/Notify";
import { useAuth } from "../../../components/Auth";
import Modality from "../../../../components/Modality/Modality";
import { FormifyInputStyle } from "../../../../assets/styles";


const ClientModal = ({ show, onClose, setTableData, id }) => {
  const { params } = useNavigator();
  const { notify } = useNotifyContext();
  const { userData: { permissions } } = useAuth();
  const defaultData = { description: "", notes: "" };

  const [ isLoading, setIsLoading ] = useState(false);
  const [ data, setData ] = useState(params[ "clients-form" ] ?? defaultData);
  const hasAccess = permissions.includes("SSP_AM_DATAMAN");
  const submitButtonLabel = hasAccess ? (isLoading ? "Loading..." : id ? "Edit" : "Add") : "No Access";

  const buttons = [
    { name: "Cancel", disabled: isLoading, onClick: () => handleClose() },
    { name: submitButtonLabel, type: "submit", styleSet: "success", disabled: isLoading || !hasAccess }
  ];

  const handleClose = () => {
    if (isLoading) return;
    setData(defaultData);
    session.delParam("clients-form");
    onClose();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (id) {
      const res = await req("update-client", { ...data });

      if (res.success) {
        setTableData(curr => ({ ...curr, data: curr.data.map(item => item.id === res.data.id ? res.data : item) }));
        notify("Success", "Client has been updated", "success");
        handleClose();
      } else {
        if (res.error === "access-denied") {
          notify("Error", "You do not have the necessary permissions", 'error');
        } else if (res.error === "already-exists") {
          notify("Error", "Client with this name already exists", 'error');
        } else {
          notify("Error", "Please contact the administrator", 'error');
        }
      }
    } else {
      const res = await req("insert-client", { ...data });

      if (res.success) {
        setTableData(curr => curr.data ? { ...curr, data: [ ...curr.data, res.data ] } : { ...curr, data: [ res.data ] });
        notify("Success", "Client has been added", "success");
        handleClose();
      } else {
        if (res.error === "access-denied") {
          notify("Error", "You do not have the necessary permissions", 'error');
        } else if (res.error === "already-exists") {
          notify("Error", "Client with this name already exists", 'error');
        } else {
          notify("Error", "Please contact the administrator", 'error');
        }
      }
    }

    setIsLoading(false);
  };

  const handleDataChange = (key, value) => {
    setData(curr => {
      const newData = { ...curr, [ key ]: value };
      session.addParam("clients-form", newData);
      return newData;
    });
  };

  useEffect(() => {
    const fetchClients = async () => {
      setIsLoading(true);

      const res = await req("select-clients", { id: id });

      if (res.success) {
        setData(res.data[ 0 ]);
      } else {
        notify("Error", "Please contact the administrator", 'error');
        handleClose();
      }

      setIsLoading(false);
    };

    if (id && show) fetchClients();
  }, [ id ]);

  return (
    <Modality show={ show } onClose={ handleClose } onSubmit={ handleSubmit } width="320px" buttons={ buttons }>

      { /* TITLE */ }
      <div className="text-xl">New Client</div>

      { /* DESCRIPTION */ }
      <div className="flex flex-col w-full">
        <div>Name</div>
        <input
          className={ FormifyInputStyle }
          required={ true }
          disabled={ isLoading || !hasAccess }
          value={ data.description }
          onInput={ (e) => handleDataChange("description", e.target.value) }
        />
      </div>

      { /* Notes */ }
      <div className="flex flex-col w-full">
        <div>Note</div>
        <textarea
          className={ FormifyInputStyle }
          required={ true }
          disabled={ isLoading || !hasAccess }
          value={ data.notes }
          onInput={ (e) => handleDataChange("notes", e.target.value) }
          rows={ 3 }
        />
      </div>

    </Modality>
  );
};

export default ClientModal;