import { createContext, useContext, useEffect, useState } from "react";
import { useScheduler } from "..";
import { ContextureButtonStyle, ContexturePanelStyle } from "../../../../assets/styles";
import { ContextureButton, ContextureCategory, ContexturePanel } from "../../../../components/Contexture/Contexture";
import Flaticon from "../../../../components/Flaticon/Flaticon";
import { clsx } from "../../../../modules/Utilkit/Utilkit";
import { useNavigator } from "../../../components/Navigator";

const ViewPanelContext = createContext();
const useViewPanel = () => useContext(ViewPanelContext);

const ViewPanelView = () => {
  const { viewMode, showPer } = useScheduler();
  const { onSubmit, isVisible, setIsVisible, options } = useViewPanel();

  return (
    <ContexturePanel
      show={ isVisible }
      className={ ContexturePanelStyle }
      simple={ true }
      onClose={ () => setIsVisible(false) }
      position={ { x: 80, y: 40 } }
    >
      <ContextureCategory label="Show per">
        { options.showPer.map(({ value, label }) => (
          <ContextureButton
            className={ ContextureButtonStyle }
            key={ value }
            onClick={ () => onSubmit('showPer', value) }
          >
            <Flaticon name="check" type="rr" size={ 11 } className={ clsx("w-1", value !== showPer && 'invisible') } />
            <span>{ label }</span>
          </ContextureButton>
        )) }
      </ContextureCategory>

      <ContextureCategory label="View Mode">
        { options.viewMode.map(({ value, label }) => (
          <ContextureButton
            className={ ContextureButtonStyle }
            key={ value }
            onClick={ () => onSubmit('viewMode', value) }
          >
            <Flaticon name="check" type="rr" size={ 11 } className={ clsx("w-1", value !== viewMode && 'invisible') } />
            <span>{ label }</span>
          </ContextureButton>
        )) }
      </ContextureCategory>

    </ContexturePanel>
  );
};

const ViewPanel= () => {
  const { setShowPer, setViewMode } = useScheduler();
  const { addQuickElement } = useNavigator();
  
  const options = {
    viewMode: [
      { value: 'days', label: 'Days' },
      { value: 'weeks', label: 'Weeks' }
    ],
    showPer: [
      { value: 'resources', label: 'Resources' },
      { value: 'projects', label: 'Project' },
    ]
  }
  
  const [ isVisible, setIsVisible ] = useState(false);

  const onSubmit = (key, value) => {
    switch(key) {
      case 'showPer':
        setShowPer(value);
        break;
      case 'viewMode':
        setViewMode(value);
        break;
    }

    setIsVisible(false);
  }

  useEffect(() => {
    addQuickElement({
      position: 0,
      id: 'select-view',
      name: "Select View",
      icon: { name: "eye", type: "rr" },
      type: "button",
      onClick: () => setIsVisible(!isVisible)
    });
  }, []);

  return (
    <ViewPanelContext.Provider value={ { onSubmit, options, isVisible, setIsVisible } }>
      <ViewPanelView />
    </ViewPanelContext.Provider>
  );
};

export { useViewPanel };
export default ViewPanel;