import { useWeGanttConsumer } from "..";
import Moment from "../../../modules/Moment/Moment";

const Timesheet = ({ timesheet, task, onClosePopup, onShowPopup }) => {
  const { range, cellHeight, cellWidth, TimesheetCell, onTimesheetClick, onTimesheetHover } = useWeGanttConsumer();

  const left = Moment(range.from).duration(timesheet.date) * cellWidth;

  return (
    <div
      style={ { width: cellWidth, height: cellHeight, top: 0, left, backgroundColor: timesheet.color || '#d1d5db' } }
      className="h-full w-full flex flex-col justify-end items-center overflow-hidden shadow-inner absolute opacity-75 cursor-pointer"
      onMouseEnter={ (event) => {
        event.currentTarget.style.filter = 'brightness(110%)';
        if (!onTimesheetHover) return false;
        const content = onTimesheetHover({ task, timesheet, event });
        if (!content) return false;
        onShowPopup({ x: event.clientX, y: event.clientY, show: true, content });
      } }
      onMouseLeave={ (event) => {
        event.currentTarget.style.filter = 'brightness(100%)';
        onClosePopup();
      } }
      onMouseDown={ (event) => {
        if (!onTimesheetClick || event.button !== 0) return false;
        onTimesheetClick({ task, timesheet, event });
      } }
    >
      { TimesheetCell && TimesheetCell(timesheet) }
    </div>
  );
};

export default Timesheet;