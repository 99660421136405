import { useEffect, useState } from "react";
import req from "../../../../modules/Request";
import { useNotifyContext } from "../../../components/Notify";
import * as session from "../../../../modules/Stash/Stash";


const useResponsables = ({ show }) => {
  const { notify } = useNotifyContext();

  const [ responsables, setResponsables ] = useState(session.getStore("responsables") || []);

  useEffect(() => {
    const fetchResponsables = async () => {
      const res = await req("select-responsables");
      if (res.success) {
        const newData = res.data.map((item) => ({ label: item.fullname, value: item.username, hashTags: item.hashTags }));
        session.addStore("responsables", newData);
        setResponsables(newData);
      } else {
        notify("Error", "Please contact the administrator", 'error');
      }
    };

    if(show) fetchResponsables();
  }, [ show ]);

  return responsables;
};

export default useResponsables;