import { useEffect, useState } from "react";
import { useNavigator } from "../../../components/Navigator";
import * as session from "../../../../modules/Stash/Stash";
import req from "../../../../modules/Request";
import { useNotifyContext } from "../../../components/Notify";
import { useAuth } from "../../../components/Auth";
import Modality from "../../../../components/Modality/Modality";
import { FormifyInputStyle } from "../../../../assets/styles";

const ActivityModal = ({ show, onClose, setTableData, id }) => {
  const { params } = useNavigator();
  const { notify } = useNotifyContext();
  const { userData: { permissions } } = useAuth();
  const defaultData = { description: "", sequence: 0 };

  const [ isLoading, setIsLoading ] = useState(false);
  const [ data, setData ] = useState(params[ "activities-form" ] ?? defaultData);
  const hasAccess = permissions.includes("SSP_AM_DATAMAN");
  const submitButtonLabel = hasAccess ? (isLoading ? "Loading..." : id ? "Edit" : "Add") : "No Access";

  const buttons = [
    { name: "Cancel", disabled: isLoading, onClick: () => handleClose() },
    { name: submitButtonLabel, type: "submit", styleSet: "success", disabled: isLoading || !hasAccess }
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (id) {
      const res = await req("update-activity", { ...data });

      if (res.success) {
        setTableData(curr => ({ ...curr, data: curr.data.map(item => item.id === res.data.id ? res.data : item) }));
        notify("Success", "Activity has been updated", "success");
        handleClose();
      } else {
        if(res.error === 'access-denied') {
          notify("Error", "You do not have the necessary permissions", 'error');
        } else {
          notify("Error", "Please contact the administrator", 'error');
        }
      }
    } else {
      const res = await req("insert-activity", { ...data });

      if (res.success) {
        setTableData(curr => curr.data ? { ...curr, data: [ ...curr.data, res.data ] } : { ...curr, data: [ res.data ] });
        notify("Success", "Activity has been added", "success");
        handleClose();
      } else {
        if(res.error === 'access-denied') {
          notify("Error", "You do not have the necessary permissions", 'error');
        } else {
          notify("Error", "Please contact the administrator", 'error');
        }
      }
    }

    setIsLoading(false);
  };

  const handleClose = () => {
    if (isLoading) return;
    setData(defaultData);
    session.delParam("activities-form");
    onClose();
  };

  const handleDataChange = (key, value) => {
    setData(curr => {
      const newData = { ...curr, [ key ]: value };
      session.addParam("activities-form", newData);
      return newData;
    });
  };

  useEffect(() => {
    const fetchJobs = async () => {
      setIsLoading(true);

      const res = await req("select-activities", { id: id });

      if (res.success) {
        setData(res.data[ 0 ]);
      } else {
        notify("Error", "Please contact the administrator", 'error');
        handleClose();
      }

      setIsLoading(false);
    };

    if (id && show) fetchJobs();
  }, [ id ]);

  return (
    <Modality show={ show } onClose={ handleClose } onSubmit={ handleSubmit } width="320px" buttons={ buttons }>
      { /* TITLE */ }
      <div className="text-xl">New Activity</div>

      { /* DESCRIPTION */ }
      <div className="flex flex-col w-full">
        <div>Name</div>
        <input
          className={ FormifyInputStyle }
          required={ true }
          disabled={ isLoading || !hasAccess }
          value={ data.description }
          onInput={ (e) => handleDataChange("description", e.target.value) }
        />
      </div>

      { /* SEQUENCE */ }
      <div className="flex flex-col w-full">
        <div>Sequence</div>
        <input
          className={ FormifyInputStyle }
          required={ true }
          type="number"
          step={ 1 }
          min={ 0 }
          disabled={ isLoading || !hasAccess }
          value={ data.sequence }
          onInput={ (e) => handleDataChange("sequence", e.target.value) }
        />
      </div>

    </Modality>
  );
};

export default ActivityModal;