import Moment from "../../../modules/Moment/Moment";

const editSplit = (workingHours, holidays, task, split, newStart, newEnd, newDuration, type, join = true) => {
  const intersection = task.dates
    .filter((date) => {
      const dateStart = new Moment(date.start).add(-1).date(0);
      const dateEnd = new Moment(date.end).add(1).date(0);
      const splitStart = new Moment(newStart).date(0);
      const splitEnd = new Moment(newEnd).date(0);

      return date.id != split.id && ((splitStart >= dateStart && splitStart <= dateEnd) || (splitEnd >= dateStart && splitEnd <= dateEnd));
    })[ 0 ];

  const out = task.dates
    .filter((date) => {
      const dateStart = new Moment(date.start).add(-1).date(0);
      const dateEnd = new Moment(date.end).add(1).date(0);
      const splitStart = new Moment(newStart).date(0);
      const splitEnd = new Moment(newEnd).date(0);

      return date.id != split.id
        && (((splitStart > dateStart && splitStart < dateEnd) || (splitEnd > dateStart && splitEnd < dateEnd))
          || ((dateStart > splitStart && dateStart < splitEnd) || (dateEnd > splitStart && dateEnd < splitEnd)));
    })[ 0 ];

  if (intersection && join) {
    let duration;
    let min;
    let max;

    if (type == "move") {
      const order = task.dates.filter((date) => date.id == split.id || intersection.id == date.id)[ 0 ] == intersection ? "after" : "before";
      duration = +intersection.duration + +split.duration;
      if (order == "before") {
        max = intersection.end;
        min = new Moment(max).start(duration / workingHours, holidays);
      } else {
        min = intersection.start;
        max = new Moment(min).end(duration / workingHours, holidays);
      }
    } else if (type == "resize") {
      const order = task.dates.filter((date) => date.id == split.id || intersection.id == date.id)[ 0 ] == intersection ? "after" : "before";
      if (order == "before") {
        if (new Moment(newEnd).day() == 0) {
          duration = +newDuration + +intersection.duration - workingHours;
        } else {
          duration = +newDuration + +intersection.duration;
        };

        max = intersection.end;
        min = new Moment(max).start((duration / workingHours), holidays);
      } else {
        duration = +newDuration + +intersection.duration - workingHours;

        min = intersection.start;
        max = new Moment(min).end((duration / workingHours), holidays);
      }
    } else {
      console.error("function editSplit: Invalid type");
      return task;
    }

    const taskDates = task.dates
      .filter((date) => intersection.id != date.id)
      .map((date) => split.id == date.id ? { ...date, start: min, end: max, duration } : date)
      .sort((a, b) => new Date(a.start) - new Date(b.start));
    if (taskDates.length > 1) {
      const taskStart = taskDates.reduce((acc, date) => new Date(date.start) < new Date(acc) ? date.start : acc, taskDates[ 0 ].start);
      const taskEnd = taskDates.reduce((acc, date) => new Date(date.end) > new Date(acc) ? date.end : acc, taskDates[ taskDates.length - 1 ].end);
      const taskDuration = new Moment(taskStart).duration(taskEnd, holidays) * workingHours;
      return { ...task, start: taskStart, end: taskEnd, duration: taskDuration, dates: taskDates };
    } else {
      return { ...task, start: min, end: max, duration, dates: undefined };
    }
  } else {
    if (out) {
      return task;
    } else {
      const taskDates = task.dates
        ?.map((date) => date.id == split.id ? { ...date, start: newStart, end: newEnd, duration: newDuration } : date)
        .sort((a, b) => new Date(a.start) - new Date(b.start));
      const taskStart = taskDates[ 0 ].start;
      const taskEnd = taskDates[ taskDates.length - 1 ].end;
      const taskDuration = new Moment(taskStart).duration(taskEnd, holidays) * workingHours;
      return { ...task, start: taskStart, end: taskEnd, duration: taskDuration, dates: taskDates };
    }
  }

};

export default editSplit;