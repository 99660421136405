import { createContext, useContext, useEffect, useMemo, useReducer, useRef, useState } from "react";
import Moment from "../../modules/Moment/Moment";
import { clsx } from "../../modules/Utilkit/Utilkit";
import Table from "./components/Table";
import Task from "./components/Task";

export const MONTH_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export const DAY_NAMES = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday"
];

const initialOffsetRange = { before: 100, after: 100 };

const WeGanttContext = createContext();

export const useWeGanttConsumer = () => {
  return useContext(WeGanttContext);
};

const offsetRangeReducer = (curr, newest) => {
  if (newest.type === 'INCREMENT_BEFORE') {
    return { ...curr, before: curr.before + newest.payload };
  } else if (newest.type === 'INCREMENT_AFTER') {
    return { ...curr, after: curr.after + newest.payload };
  } else {
    return curr;
  }
};

const WeGantt = ({
  cellWidth,
  cellHeight,
  fontSize,
  tasks,
  styles,
  displayHolidays,
  holidays = [],
  workingHours = 8,
  onMove,
  onResize,
  onEmptyCellClick,
  onTaskClick,
  onTaskDoubleClick,
  onTaskContextMenu,
  onTaskHover,
  onMilestoneClick,
  onMilestoneHover,
  onTimesheetClick,
  onTimesheetHover,
  TableColumns,
  TableRow,
  TimesheetCell,
  MilestoneCell,
  TaskCell,
  viewMode = 'days'
}) => {
  const scrollRef = useRef(null);
  const ganttHeaderRef = useRef(null);
  const tableHeaderRef = useRef(null);
  const firstRender = useRef(true);

  const [ offsetRange, dispatch ] = useReducer(offsetRangeReducer, initialOffsetRange);
  const [ range, setRange ] = useState(undefined);

  /** Process Data */
  const data = useMemo(() => {
    if (!tasks) return [];

    const filteredTasks = [ ...tasks ].reduce((acc, task) => {
      task.space = task.position.match(/\.+/g)?.length ?? 0;

      if (task.dates?.length > 0) {
        task.dates = task.dates.map((date) => {
          if (date.duration) {
            return {
              ...date,
              end: new Moment(date.start).end((date.duration / workingHours), holidays)
            };
          } else if (date.end) {
            return {
              ...date,
              duration: (Moment(date.start).duration(date.end, { blacklist: holidays }) + 1) * workingHours
            };
          } else {
            console.error('Invalid date format');
            return date;
          }
        });

        task.start = task.dates[ 0 ]?.start || new Date().toISOString().split('T')[ 0 ];
        task.end = task.dates[ task.dates.length - 1 ].end;
        task.duration = task.dates.reduce((acc, date) => acc + date.duration, 0);
      } else {
        if (task.start && !task.duration && task.end) {
          task.duration = (Moment(task.start).duration(task.end, { blacklist: holidays }) + 1) * workingHours;
        } else if (task.start && !task.end && task.duration) {
          task.end = Moment(task.start).end(task.duration / workingHours, holidays);
        }
      }


      const childs = [ ...tasks ].filter(child => child.position.startsWith(task.position + '.'));
      if (childs.length > 0) {
        task.showChilds = task.showChilds ?? false;

        if (task.type == 'project') {
          task.start = childs.reduce((acc, child) => {
            if (child.type != 'milestone' && child.start && new Date(child.start) < new Date(acc)) {
              acc = child.start;
            }

            return acc;
          }, childs[ 0 ].start ?? new Date().toISOString().split('T')[ 0 ]);

          task.end = childs.reduce((acc, child) => {
            if (child.type != 'milestone' && child.end && new Date(child.end) > new Date(acc)) {
              acc = child.end;
            }


            return acc;
          }, childs[ 0 ].end ?? new Date().toISOString().split('T')[ 0 ]);

          task.duration = new Moment(task.start).duration(task.end, { blacklist: holidays }) * 8;
        }
      } else {
        task.showChilds = undefined;
      }



      const parents = [ ...tasks ].filter(parent => task.position.startsWith(parent.position + '.'));
      if (parents.every(parent => parent.showChilds !== false)) {
        acc.push(task);
      }

      return acc;
    }, []);

    const sortedTasks = [ ...filteredTasks ].sort((a, b) => {
      const versionA = a.position.split('.').map(Number);
      const versionB = b.position.split('.').map(Number);

      for (let i = 0; i < Math.max(versionA.length, versionB.length); i++) {
        const numA = versionA[ i ] || 0;
        const numB = versionB[ i ] || 0;

        if (numA < numB) {
          return -1;
        } else if (numA > numB) {
          return 1;
        }
      }

      return 0;
    });

    return sortedTasks;
  }, [ tasks ]);

  /** Process Range */
  useEffect(() => {
    if (!(data?.length > 0)) return;

    const startDates = data.map(task => {
      if (task.dates?.length > 0) {
        return new Date(task.dates[ 0 ].start).getTime();
      } else if (task.start) {
        return new Date(task.start).getTime();
      } else if (task.date) {
        return new Date(task.date).getTime();
      }
    }).filter(date => date && date !== 'Invalid Date');


    const endDates = data.map(task => {
      if (task.dates?.length > 0) {
        return new Date(task.dates[ task.dates.length - 1 ].end).getTime();
      } else if (task.end) {
        return new Date(task.end).getTime();
      } else if (task.duration) {
        return new Date(new Moment(task.start).end(task.duration / workingHours, holidays)).getTime();
      } else {
        return new Date(task.start).getTime();
      }
    }).filter(date => date && date !== 'Invalid Date');

    if (startDates.length === 0 || endDates.length === 0) {
      const minDate = new Date();
      const maxDate = new Date();

      minDate.setDate(minDate.getDate() - offsetRange.before);
      maxDate.setDate(maxDate.getDate() + offsetRange.after);

      setRange({
        from: minDate.toISOString().split('T')[ 0 ],
        to: maxDate.toISOString().split('T')[ 0 ]
      });
    } else {
      const minDate = new Date(Math.min(...startDates));
      const maxDate = new Date(Math.max(...endDates));

      minDate.setDate(minDate.getDate() - offsetRange.before);
      maxDate.setDate(maxDate.getDate() + offsetRange.after);

      if (range === undefined || (minDate <= new Date(range.from) && maxDate >= new Date(range.to))) {
        setRange({
          from: minDate.toISOString().split('T')[ 0 ],
          to: maxDate.toISOString().split('T')[ 0 ]
        });
      } else if (minDate <= new Date(range.from)) {
        setRange({
          ...range,
          from: minDate.toISOString().split('T')[ 0 ],
        });
      } else if (maxDate >= new Date(range.to)) {
        setRange({
          ...range,
          to: maxDate.toISOString().split('T')[ 0 ],
        });
      }
    }
  }, [ offsetRange, data ]);

  useEffect(() => {
    if (!firstRender.current) {
      scrollRef.current.scrollLeft = 0;
    }
  }, [ offsetRange ]);

  const days = useMemo(() => {
    if (!range) return [];

    return new Moment(range.from).map(range.to, (date) => ({
      date: date.value(),
      holiday: holidays && holidays.includes(date.value()),
      displayHoliday: displayHolidays && displayHolidays.includes(date.value()),
      day: date.get('date'),
      today: date.isToday('day'),
      month: date.get('month') + 1,
      year: date.get('year'),
      dayOfWeek: date.day(),
      dayOfWeekName: date.dayname(DAY_NAMES),
      left: date.left() + 1
    }));
  }, [ range, holidays ]);

  const widthOfGantt = useMemo(() => viewMode === 'weeks' ? (
    days.filter(day => day.dayOfWeek === 0).length * cellWidth
  ) : (
    days.length * cellWidth
  ), [ viewMode, days ]);
  const heightOfHeader = useMemo(() => cellHeight * (viewMode === 'weeks' ? 2 : 4), [ viewMode, cellHeight ]);

  useEffect(() => {
    if (days?.length > 0 && firstRender.current) {
      scrollRef.current.scrollLeft = (new Moment(range.from).duration(new Date().toISOString().split('T')[ 0 ]) - 10) * cellWidth;
      firstRender.current = false;
    }
  }, [ days ]);

  const handleVerticalScroll = (e) => {
    if (ganttHeaderRef.current)
      ganttHeaderRef.current.style.top = `${e.target.scrollTop}px`;

    if (tableHeaderRef.current)
      tableHeaderRef.current.style.top = `${e.target.scrollTop}px`;
  };

  const handleHorizontalScroll = (e) => {
    /*const target = e.currentTarget;

    const offsetRange = 28;
    const offsetLeft = viewMode === 'weeks' ? offsetRange / 7 : offsetRange;

    if (target.scrollLeft === 0 && !firstRender.current) {
      dispatch({ type: 'INCREMENT_BEFORE', payload: offsetRange });
      target.scrollLeft = offsetLeft * cellWidth;
    } else if (target.scrollLeft + target.clientWidth === target.scrollWidth) {
      dispatch({ type: 'INCREMENT_AFTER', payload: offsetRange });
    }*/
  };

  return days && data && range && (
    <WeGanttContext.Provider value={ {
      cellWidth,
      cellHeight,
      widthOfGantt,
      heightOfHeader,
      fontSize,
      workingHours,
      holidays,
      styles,
      days,
      range,
      onMove,
      onResize,
      onEmptyCellClick,
      onTaskClick,
      onTaskDoubleClick,
      onTaskContextMenu,
      onTaskHover,
      onMilestoneClick,
      onMilestoneHover,
      onTimesheetClick,
      onTimesheetHover,
      TimesheetCell,
      MilestoneCell,
      TableColumns,
      TableRow,
      TaskCell,
      viewMode
    } }>
      <div
        onScroll={ handleVerticalScroll }
        className="flex relative overflow-x-hidden overflow-y-auto h-full w-full"
      >
        <Table tasks={ data } />

        <div
          ref={ scrollRef }
          style={ { width: widthOfGantt, maxWidth: widthOfGantt } }
          className="h-min overflow-x-auto relative overflow-y-hidden"
          onScroll={ handleHorizontalScroll }
        >
          { /* GANTT HEADER */ }
          <div
            ref={ ganttHeaderRef }
            style={ { width: widthOfGantt } }
            className="w-full absolute z-30 shadow-sm"
          >
            { viewMode === 'weeks' ? (
              <WeGanttHeaderPerWeeks />
            ) : (
              <WeGanttHeaderPerDays />
            ) }
          </div>

          { /* GANTT GRID */ }
          <div
            className="h-full select-none"
            style={ {
              width: widthOfGantt,
              marginTop: heightOfHeader
            } }
          >
            { viewMode === 'weeks' ? (
              <WeGanttGridPerWeeks data={ data } />
            ) : (
              <WeGanttGridPerDays data={ data } />
            ) }

            { /* GANTT TASKS */ }
            { data.map((task) => (
              <Task key={ task.id } task={ task } />
            )) }
          </div>
        </div>
      </div>
    </WeGanttContext.Provider>
  );
};

const WeGanttHeaderPerDays = () => {
  const { cellWidth, cellHeight, fontSize, styles, days } = useWeGanttConsumer();

  return (
    <>
      <div
        style={ { height: `${cellHeight}px`, fontSize: fontSize } }
        className={ clsx("flex items-center overflow-hidden text-ellipsis p-0 font-semibold", styles?.border ?? "border-gray-200") }
      >
        { days.map((day, index) => {
          if (day.day === 1 || index === 0 || (day.day === 1 && day.month === days[ days.length - 1 ].month && day.year === days[ days.length - 1 ].year)) {
            const daysInMonth = Moment(day.date).monthdays();
            return (
              <div
                key={ index }
                className={ clsx(
                  "h-full border-b flex text-ellipsis text-nowrap select-none justify-center items-center",
                  "border-r bg-white border-gray-200 font-semibold hover:shadow-[inset_0_0_4px_0_#00000030]"
                ) }
                style={ {
                  minWidth: (index === 0 ? (
                    daysInMonth + 1 - day.day
                  ) : (day.month === days[ days.length - 1 ].month && day.year === days[ days.length - 1 ].year) ? (
                    days[ days.length - 1 ].day
                  ) : (
                    daysInMonth
                  )) * cellWidth
                } }
              >
                <div>
                  { Moment(day.date).monthname(MONTH_NAMES) } { day.year }
                </div>
              </div>
            );
          }
        }) }
      </div>

      <div
        style={ { height: `${cellHeight}px`, fontSize: fontSize } }
        className={ clsx("flex items-center overflow-hidden text-ellipsis border-b font-semibold", styles?.border ?? "border-gray-200") }
      >
        { days.filter((d, i) => i == 0 || d.dayOfWeek == 0).map((d, i) => {
          const last = days[ days.length - 1 ];
          const isLast = last.year == d.year && last.dayOfWeek == d.dayOfWeek;
          return (
            <div
              key={ i }
              className={ "h-full flex select-none justify-center items-center border-r bg-white border-gray-200 hover:shadow-[inset_0_0_4px_0_#00000030]" }
              style={ { minWidth: (7 - d.dayOfWeek - (isLast ? last.dayOfWeek : 0)) * cellWidth } }
            >
              <div>
                { new Moment(d.date).week() }
              </div>
            </div>
          );
        })
        }
      </div>

      <div
        style={ { height: `${cellHeight}px`, fontSize: fontSize } }
        className={ clsx("flex items-center overflow-hidden text-ellipsis border-gray-200", styles?.border ?? "") }
      >
        { days.map((day, index) => (
          <div
            className={
              clsx(
                "h-full border-b flex select-none justify-center items-center border-r text-nowrap font-semibold hover:shadow-[inset_0_0_4px_0_#00000030]",
                day.styles || "bg-white",
                styles?.border ?? "border-gray-200"
              )
            }
            style={ { minWidth: cellWidth, backgroundColor: day.today ? styles?.todayColor : day.displayHoliday ? styles?.holidayColor : "white" } }
            key={ index }
          >
            <div>
              { day.day }
            </div>
          </div>
        )) }
      </div>


      <div
        style={ { height: `${cellHeight}px`, fontSize: fontSize } }
        className={ clsx("flex items-center overflow-hidden text-ellipsis border-gray-200", styles?.border ?? "") }
      >
        { days.map((day, index) => (
          <div
            className={
              clsx(
                "h-full border-b flex select-none justify-center items-center border-r text-nowrap font-semibold hover:shadow-[inset_0_0_4px_0_#00000030]",
                day.styles || "bg-white",
                styles?.border ?? "border-gray-200"
              )
            }
            style={ { minWidth: cellWidth, backgroundColor: day.today ? styles?.todayColor : day.displayHoliday ? styles?.holidayColor : "white" } }
            key={ index }
          >
            <div>
              { Moment(day.date).dayname(DAY_NAMES).slice(0, 1) }
            </div>
          </div>
        )) }
      </div>
    </>
  );
};

const WeGanttHeaderPerWeeks = () => {
  const { cellWidth, cellHeight, fontSize, styles, days } = useWeGanttConsumer();

  return (
    <>
      <div
        style={ { height: cellHeight, fontSize: fontSize } }
        className={ clsx(
          "flex items-center overflow-hidden text-ellipsis p-0 font-semibold",
          styles?.border ?? "border-gray-200"
        ) }
      >
        { days.map((day, index) => {
          if (day.day === 1 || index === 0 || (day.day === 1 && Moment(day.date).same(days[ days.length - 1 ], 'month'))) {
            const weeksInMonth = Moment(day.date).weeksInMonth();
            const width = cellWidth * (index === 0 ? (
              weeksInMonth - Moment(day.date).week('month') + 1
            ) : Moment(day.date).same(days[ days.length - 1 ].date, 'month') ? (
              Moment(days[ days.length - 1 ].date).week('month')
            ) : (
              weeksInMonth
            ));

            return (
              <div
                key={ day.date }
                className={ clsx(
                  "h-full border-b flex text-nowrap select-none justify-center items-center overflow-x-hidden",
                  "border-r bg-white border-gray-200 font-semibold hover:shadow-[inset_0_0_4px_0_#00000030]"
                ) }
                style={ {
                  minWidth: width,
                  maxWidth: width,
                  width
                } }
              >
                <div>
                  { Moment(day.date).monthname(MONTH_NAMES).substring(0, 3) } { day.year }
                </div>
              </div>
            );
          }
        }) }
      </div>

      <div
        style={ { height: `${cellHeight}px`, fontSize: fontSize } }
        className="flex items-center overflow-hidden text-ellipsis border-gray-200"
      >
        { days.map((day) => (
          day.dayOfWeek === 0 && (
            <div
              key={ day.date }
              className={ clsx(
                "h-full border-b flex select-none justify-center items-center border-r text-nowrap",
                "font-semibold hover:shadow-[inset_0_0_4px_0_#00000030]"
              ) }
              style={ {
                minWidth: cellWidth,
                maxWidth: cellWidth,
                width: cellWidth,
                backgroundColor: Moment(day.date).isToday('week') ? styles?.todayColor ?? 'white' : 'white'
              } }
            >
              <div>
                { Moment(day.date).week('year') }
              </div>
            </div>
          )
        )) }
      </div>
    </>
  );
};

const WeGanttGridPerDays = ({ data }) => {
  const { cellWidth, cellHeight, days, styles } = useWeGanttConsumer();

  return (
    <svg className="bg-white absolute z-20" width={ cellWidth * days.length } height={ data.length * cellHeight }>
      { days.map((day, index) => {
        return (
          <g key={ day.date } data-day={ day.date }>
            <line
              className="stroke-width-1 stroke-slate-200 flex select-none justify-center items-center"
              x1={ index * cellWidth }
              y1={ 0 }
              x2={ index * cellWidth }
              y2={ data.length * cellHeight }
            />

            { day.today ? (
              <rect
                className="stroke-width-1 stroke-slate-200 flex select-none justify-center items-center"
                style={ { fill: styles?.todayColor ?? "white" } }
                x={ index * cellWidth }
                y={ 0 }
                width={ cellWidth }
                height={ data.length * cellHeight }
              />
            ) : day.displayHoliday && (
              <rect
                className="stroke-width-1 stroke-slate-200 flex select-none justify-center items-center"
                style={ { fill: styles?.holidayColor ?? "white" } }
                x={ index * cellWidth }
                y={ 0 }
                width={ cellWidth }
                height={ data.length * cellHeight }
              />
            ) }
          </g>
        );
      }) }

      { data.map((task, index) => (
        <g key={ task.id } data-task={ task.id }>
          <line
            className="stroke-width-1 stroke-slate-200 flex select-none justify-center items-center"
            x1={ 0 }
            y1={ (index + 1) * cellHeight }
            x2={ days.length * cellWidth }
            y2={ (index + 1) * cellHeight }
          />
        </g>
      )) }
    </svg>
  );
};

const WeGanttGridPerWeeks = ({ data }) => {
  const { cellWidth, cellHeight, days, styles } = useWeGanttConsumer();
  const daysWithWeeks = days.filter(day => day.dayOfWeek === 0);

  return (
    <svg className="bg-white absolute z-20" width={ cellWidth * daysWithWeeks.length } height={ data.length * cellHeight }>
      { Array.from({ length: data.length }).map((_, i) => (
        <g key={ i }>
          { daysWithWeeks.map((day, j) => {
            return (
              <g key={ day.date }>
                { (Moment(day.date).isToday('week')) && (
                  <rect
                    className="stroke-width-1 stroke-slate-200 flex select-none justify-center items-center"
                    style={ { fill: styles?.todayColor ?? '#AAAAAA' } }
                    x={ j * cellWidth }
                    y={ i * cellHeight }
                    width={ cellWidth }
                    height={ cellHeight }
                  />
                ) }
                { i === 0 && (
                  <line
                    className="stroke-width-1 stroke-slate-200 flex select-none justify-center items-center"
                    x1={ j * cellWidth }
                    y1={ 0 }
                    x2={ j * cellWidth }
                    y2={ data.length * cellHeight }
                  />
                ) }
              </g>
            );
          }) }
          <line
            className="stroke-width-1 stroke-slate-200 flex select-none justify-center items-center"
            key={ i }
            x1={ 0 }
            y1={ (i + 1) * cellHeight }
            x2={ days.length * cellWidth }
            y2={ (i + 1) * cellHeight }
          />
        </g>
      )) }
      { data.map((task, index) => (
        <g key={ task.id } data-task={ task.id }>
          { task.dates?.map((split, splitIndex) => {
            const startWeek = new Moment(split.start).week();
            const endWeek = new Moment(split.end).week();
            if (startWeek === endWeek) {
              const x = new Moment(days[0].date).duration(split.start, { size: 'week' }) * cellWidth;
              const width = new Moment(split.start).duration(split.end, { size: 'week' }) * cellWidth;
              return (
                <rect
                  key={ splitIndex }
                  x={ x }
                  y={ index * cellHeight }
                  width={ width }
                  height={ cellHeight }
                  style={ { fill: split.color || '#3b82f6' } }
                />
              );
            }
            return null;
          }) }
        </g>
      )) }
    </svg>
  );
};

export default WeGantt;
