import { useEffect, useRef } from "react";
import { useWeGanttConsumer } from "..";
import Moment from "../../../modules/Moment/Moment";
import { clsx } from "../../../modules/Utilkit/Utilkit";
import { Vector2D } from "../../../modules/Vecto/Vecto";

const Milestone = ({ task, rowRef, onShowPopup, onClosePopup }) => {
  const { onMove, days, holidays, workingHours, cellWidth, cellHeight, onMilestoneHover, onMilestoneClick,
    onMilestoneContext, MilestoneCell, range, onTaskContextMenu } = useWeGanttConsumer();

  const move = useRef(false);
  const clickPos = useRef(0);
  const ref = useRef(null);
  let x = new Moment(range.from).duration(task.date) * cellWidth;

  useEffect(() => {
    const handleMouseUp = (e) => {
      if (move.current) {
        e.preventDefault();
        move.current = false;
        try {
          const pos = new Vector2D(ref.current.offsetLeft + (cellWidth / 2)).div(cellWidth).floor();
          let newStartMoment = new Moment(range.from).add(pos.x());

          const newStart = newStartMoment.available(holidays, "prev");
          const newEnd = new Moment(newStart).end(task.duration / workingHours, holidays);

          if (newStart !== task.date && onMove) {
            onMove({ ...task, date: newStart, start: newStart, end: newEnd });
          } else {
            // CLICKED
          }
        } catch (e) {
          console.error(e);
        }
        ref.current.style.left = `${x}px`;
      }
    };

    const handleMouseMove = (e) => {
      if (move.current) {
        const newX = e.clientX - clickPos.current;
        ref.current.style.left = `${newX}px`;
      }
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [ x, days, task ]);

  return (
    <div
      ref={ ref }
      className="absolute flex select-none justify-center items-center"
      style={ { top: 0, left: `${x}px`, width: `${cellWidth}px`, height: cellHeight } }
    >
      <div
        onMouseLeave={ onClosePopup }
        onMouseEnter={ (event) => {
          if (!onMilestoneHover) return;
          const content = onMilestoneHover({ task, event });
          if (!content) return;
          onShowPopup({ event, content });
        } }
        onMouseDown={ (e) => {
          e.preventDefault();
          if (e.button === 0 && task.isDraggable) {
            move.current = true;
            clickPos.current = e.clientX - ref.current.offsetLeft;
          }
        } }
        onContextMenu={ (event) => {
          if (!onTaskContextMenu) return;
          event.preventDefault();
          const position = new Vector2D().point(event, rowRef.current).div(cellWidth).floor();
          const selDate = new Moment(range.from).add(position.x()).value();
          onTaskContextMenu({ task: { ...task, selDate }, event });
        } }
      >
        { MilestoneCell ? (
          <MilestoneCell task={ task } from={ range.from } />
        ) : (
          <div
            className={ clsx(
              "rotate-45 w-3 h-3 cursor-pointer bg-green-500 hover:bg-green-400",
              task.styles ?? "") }
          />
        ) }
      </div>
    </div>
  );
};

export default Milestone;