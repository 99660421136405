import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Listbox, ListboxButton, ListboxOption, ListboxOptions, Radio, RadioGroup } from "@headlessui/react";
import React, { useEffect, useRef, useState } from "react";

const clsx = (...args) => args.filter(Boolean).join(" ");

const FormifySelect = ({ onChange, children, value, className, name, placeholder = 'Select...' }) => {
  const listboxButtonRef = useRef(null);
  const [ optionsWidth, setOptionsWidth ] = useState('auto');
  const filteredChildren = React.Children.toArray(children).filter(child => child.type === FormifySelectOption);

  useEffect(() => {
    if (listboxButtonRef.current) {
      setOptionsWidth(listboxButtonRef.current.offsetWidth + "px");
    }
  }, [ listboxButtonRef.current ]);

  return (
    <Listbox value={ value } onChange={ (val) => onChange(val) }>
      <ListboxButton ref={ listboxButtonRef } className={ className?.Select + " relative" ?? "" } name={ name }>
        <span className="w-full text-start">{ filteredChildren.find((child) => child.props.value === value)?.props.label ?? placeholder }</span>

        <i className={ clsx(className?.ComboboxInputArrowIcon, "flex justify-center items-center fi fi-bs-angle-down text-[12px] group pointer-events-none absolute top-2.5 right-2.5") } />
      </ListboxButton>

      <ListboxOptions
        className={ (className?.Options ?? "") + " contexture"  }
        transition={ true }
        anchor="bottom start"
        style={{ width: optionsWidth }}
      >
        { filteredChildren.map((child) => (
          <FormifySelectOption
            key={ child.props.value }
            value={ child.props.value }
            label={ child.props.label }
            className={ child.props.className }
          >
            { child.props.children }
          </FormifySelectOption>
        )) }
      </ListboxOptions>
    </Listbox>
  );
};

const FormifySelectOption = ({ value, label, className, children }) => {
  return (
    <ListboxOption
      value={ value }
      label={ label }
      className={ className }
    >
      { children }
    </ListboxOption>
  );
};

const FormifyCombobox = ({ onChange, className, children, disabled, value, autoComplete, required, name }) => {
  const [ query, setQuery ] = useState('');
  const comboboxInputRef = useRef(null);
  const [ optionsWidth, setOptionsWidth ] = useState('auto');

  useEffect(() => {
    if (comboboxInputRef.current) {
      setOptionsWidth(comboboxInputRef.current.offsetWidth + "px");
    }
  }, [ comboboxInputRef.current ]);

  const filteredChildren = query !== '' ? (
    React.Children.toArray(children).filter((child) => {
      if (child.type !== FormifyComboboxOption) return false;

      const splitQ = query.toLowerCase().split(' ');

      const validated = splitQ.every((q) => {
        const label = child.props.label.toLowerCase();
        const hashtags = child.props.hashTags?.map((tag) => tag.toLowerCase()) ?? [];

        if (q.startsWith('#') && hashtags?.length > 0) {
          const hashtagsValidated = hashtags?.some((tag) => tag.includes(q.slice(1)));
          const labelValidated = label.includes(q);

          return hashtagsValidated || labelValidated;
        } else {
          return label.includes(q);
        }
      });

      return validated;
    })
  ) : (
    React.Children.toArray(children).filter((child) => child.type === FormifyComboboxOption)
  );

  const displayValue = (value) => React.Children.toArray(children).find(child => child.props.value === value)?.props.label;

  return (
    <Combobox immediate value={ value } onChange={ onChange } onClose={ () => setQuery('') }>
      <div className="relative w-full">
        <ComboboxInput
          name={ name }
          ref={ comboboxInputRef }
          required={ required }
          disabled={ disabled }
          autoComplete={ autoComplete }
          className={ className?.ComboboxInput ?? "" }
          displayValue={ displayValue }
          onChange={ (e) => setQuery(e.target.value) }
        />

        <ComboboxButton className={ className?.ComboboxInputArrowButton ?? "" }>
          <i
            className={ clsx(
              className?.ComboboxInputArrowIcon,
              "flex justify-center items-center fi fi-bs-angle-down text-[12px]"
            ) }
          />
        </ComboboxButton>
      </div>

      <ComboboxOptions
        anchor="bottom start"
        className={ "contexture " + className?.ComboboxOptions }
        style={{ width: optionsWidth }}
      >
        { filteredChildren?.length > 0 && filteredChildren.map((child, i) => (
          <FormifyComboboxOption
            key={ i }
            value={ child.props.value }
            className={ child.props.className }
          >
            { child.props.children }
          </FormifyComboboxOption>
        )) }
      </ComboboxOptions>
    </Combobox>
  );
};

const FormifyComboboxOption = ({ className, value, label, hashTags, children }) => {
  return (
    <ComboboxOption
      className={ className }
      value={ value }
    >
      { children }
    </ComboboxOption>
  );
};

const FormifySingleSelect = ({ onChange, selected, children }) => {
  return React.Children.map(children, (child, i) => (
    child.type === FormifySingleSelectOption ? (
      <FormifySingleSelectOption
        key={ i }
        onClick={ () => onChange(child.props.value) }
        isSelected={ selected === child.props.value }
        className={ child.props.className }
      >
        { child }
      </FormifySingleSelectOption>
    ) : null
  ));
};

const FormifySingleSelectOption = ({ children, className, value, ...props }) => {
  return (
    <button className={ className } onClick={ props.onClick }>
      { children instanceof Function ? children({ isSelected: props.isSelected }) : children }
    </button>
  );
}

const FormifyLevel = ({ onChange, className, from, to, disabled, value }) => {
  return (
    <RadioGroup disabled={ disabled } value={ value } onChange={ onChange }>
      <div className="flex justify-between space-x-1">
        { Array.from({ length: to - from + 1 }, (_, i) => i + from).map((level, i) => (
          <Radio key={ i } value={ level } className={ className }>
            { level }
          </Radio>
        )) }
      </div>
    </RadioGroup>
  );
};

const FormifyMultiSelect = ({ children, selected, onChange, noEmpty }) => {
  const options = React.Children.map(children, child => child.props.value);

  const selectedAll = selected.length === options.length;
  const selectedHalf = selected.length > 0 && selected.length < options.length;

  const handleSelect = (value) => {
    const newValues = selected.includes(value) ? selected.filter(val => val !== value) : [...selected, value];
    if (!(noEmpty && newValues.length === 0)) {
      onChange(newValues);
    }
  };

  const handleSelectAll = () => {
    onChange(!noEmpty && selectedAll ? [] : options);
  };

  useEffect(() => {
    if (noEmpty && selected.length === 0) {
      onChange(options);
    }
  }, [noEmpty, selected, onChange, options]);

  return React.Children.map(children, (child, i) => {
    if (child.type === FormifyMultiOption) {
      return (
        <FormifyMultiOption
          key={i}
          className={child.props.className}
          onClick={() => handleSelect(child.props.value)}
          isSelected={selected.includes(child.props.value)}
        >
          {child.props.children}
        </FormifyMultiOption>
      );
    } else if (child.type === FormifyMultiAll) {
      return (
        <FormifyMultiAll
          key={i}
          className={child.props.className}
          onClick={handleSelectAll}
          isSelectedAll={selectedAll}
          isSelectedHalf={selectedHalf}
        >
          {child.props.children}
        </FormifyMultiAll>
      );
    } else {
      return null;
    }
  });
};

const FormifyMultiOption = ({ children, className, ...props }) => {
  return (
    <button onClick={ props.onClick } className={ className }>
      { children instanceof Function ? children({ isSelected: props.isSelected }) : children }
    </button>
  );
};

const FormifyMultiAll = ({ children, className, ...props }) => {
  return (
    <button onClick={ props.onClick } className={ className }>
      { children instanceof Function ? children({ isSelectedAll: props.isSelectedAll, isSelectedHalf: props.isSelectedHalf }) : children }
    </button>
  );
};

export {
  FormifyCombobox,
  FormifyComboboxOption,
  FormifyLevel, FormifyMultiAll, FormifyMultiOption, FormifyMultiSelect,
  FormifySelect, FormifySelectOption,
  FormifySingleSelect, FormifySingleSelectOption
};
