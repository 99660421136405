import { useState } from "react";
import sls from "react-secure-storage";

const setSession = (session) => {
  sls.setItem('session', session);
}

const getSession = () => {
  return sls.getItem('session');
}

const delSession = () => {
  sls.removeItem('session');
}

const setParams = (params) => {
  const session = getSession();
  session.params = params;
  setSession(session);
}

const getParams = () => {
  const session = getSession();
  return session?.params;
}

const addParam = (key, value) => {
  const session = getSession();
  session.params[key] = value;
  setSession(session); 
}

const getParam = (key) => {
  const session = getSession();
  return session.params[key];
}

const delParam = (key) => {
  const session = getSession();
  delete session.params[key];
  setSession(session);
}

const setActive = (active) => {
  const session = getSession();
  session.active = active;
  setSession(session);
}

const getActive = () => {
  const session = getSession();
  return session?.active;
}

const addStore = (key, value) => {
  const store = sls.getItem('store') || {};
  store[key] = value;
  sls.setItem('store', store);
  return value;
}

const getStore = (key) => {
  const store = sls.getItem('store');
  return store?.[key];
}

const store = (key, value) => {
  if(value) {
    return addStore(key, value);
  } else {
    return getStore(key);
  }
}

const useStash = (key, defaultValue, forceValue) => {
  const [ value, setValue ] = useState(forceValue || store(key) || defaultValue);
  const setStore = (value) => {
    setValue(addStore(key, value));
  }
  return [ value, setStore ];
}

export { addParam, addStore, delParam, delSession, getActive, getParam, getParams, getSession, getStore, setActive, setParams, setSession, store, useStash };
