import { createContext, useContext, useMemo } from "react";
import Flaticon from "../../../components/Flaticon/Flaticon";
import WeGantt from "../../../components/WeGantt";
import req from "../../../modules/Request";
import { useStash } from "../../../modules/Stash/Stash";
import { useContextMenu } from "../../components/ContextMenu";
import { useNavigator } from "../../components/Navigator";
import { useNotifyContext } from "../../components/Notify";
import FilterPanel from "./components/FilterPanel";
import ViewPanel from "./components/ViewPanel";
import useCalendar from "./hooks/useCalendar";
import useTasks from "./hooks/useTasks";

const FONT_SIZE = 11;
const CELL_HEIGHT = 20;
const CELL_WIDTH = 20;

const SchedulerContext = createContext();
const useScheduler = () => useContext(SchedulerContext);

const Scheduler = () => {
  //#region [contexts] - Notifications, Navigator, ContextMenu
  const { notify } = useNotifyContext();

  const { go, params } = useNavigator();

  const { setContextMenu } = useContextMenu();
  //#endregion

  //#region [states] - showPanel, viewMode, showPer, range
  const [ viewMode, setViewMode ] = useStash('view-mode', 'days', params[ 'view-mode' ]);
  const [ showPer, setShowPer ] = useStash('show-per', 'resources', params[ 'show-per' ]);
  const [ filters, setFilters ] = useStash('filters', { offices: [], travels: [], interventions: [], installations: [], resources: [], responsables: [], range: { from: '', to: '' }, showCompleted: 0 }, params[ "filters" ]);
  //#endregion

  //#region [hooks] - calendar, tasks
  const { calendar } = useCalendar();

  const { tasks, setTasks } = useTasks({ showPer, calendar, range: filters.range });
  //#endregion

  //#region [validate] - isValidFilter
  const isValidFilter = (filter, value) => filter.length === 0 || filter.includes(value);
  //#endregion

  //#region [render] - renderTableColumns, renderTableRow
  const renderTableColumns = () => {
    if (showPer === 'projects') {
      return [
        <div className="flex px-2 min-w-32 w-full">Name</div>
      ];
    } else {
      return [
        <div className="flex px-2 min-w-32 w-full">Name</div>,
        <div className="flex px-2 min-w-32 w-full">Office</div>,
        <div className="flex px-2 min-w-32 w-full">Travel</div>
      ];
    }
  };

  const renderTableRow = ({ name, office, travelAvailability, space, showChilds, id, record }) => {
    if (showPer === 'projects') {
      return (
        <tr
          onContextMenu={ (e) => {
            e.preventDefault();
            if (record === 'project') {
              setContextMenu({
                context: [ [
                  {
                    name: 'Edit', icon: { name: 'edit', type: 'rr' }, onClick: () => {
                      go('data-management', {
                        "form-modal": { show: 'projects-form', id },
                        "selected-topic": "projects"
                      });
                    }
                  }
                ] ],
                position: { x: e.clientX, y: e.clientY }
              });
            }
          } }
          style={ { padding: (space * 10) + 'px' } }
        >
          <td style={ { fontSize: FONT_SIZE, height: CELL_HEIGHT } } className="min-w-12 px-2 py-0 border-b border-gray-200 border-r">
            { showChilds !== undefined && (
              <button onClick={ () => setTasks(curr => curr.map((t) => t.id == id ? { ...t, showChilds: !t.showChilds } : t)) }>
                { showChilds ? (
                  <Flaticon
                    name="angle-down"
                    className="pr-1"
                    type="rr"
                    size={ FONT_SIZE }
                  />
                ) : (
                  <Flaticon
                    name="angle-right"
                    className="pr-1"
                    type="rr"
                    size={ FONT_SIZE }
                  />
                ) }
              </button>
            ) }
            <span>{ name }</span>
          </td>
        </tr>
      );
    } else {

      return (
        <tr
          style={ { fontSize: FONT_SIZE, height: '20px' } }
          className="*:min-w-10 *:border-gray-200 *:h-full"
        >
          <td style={ { paddingLeft: (space * 10) + 10 } } className="border-b border-r">
            { name }
          </td>
          <td className="px-2 border-b border-r">
            { office }
          </td>
          <td className="px-2 border-b">
            { travelAvailability }
          </td>
        </tr>
      );
    }
  };
  //#endregion

  //#region [handlers] - handleMove, handleResize, handleContextMenu
  const handleMove = (task) => {
    if (showPer === 'projects') {
      setTasks(tasks.map((t) => {
        if (t.id == task.id) {
          req('update-task', {
            id: task.id.replace('task-', ''),
            start: task.start,
            end: task.end,
            showPer: 'projects'
          }).then((res) => {
            if (res.error) {
              notify("Move Task Error", "Please contact the administrator", 'error');
            }
          });

          return {
            ...t,
            start: task.start,
            end: task.end,
            duration: undefined
          };
        } else {
          return t;
        }
      }));
    } else {
      setTasks(tasks.map((t) => {
        if (t.id == task.id && t.dates.length == task.dates.length) {
          const split = task.dates.find((d) => d.id == task.splitID);
          req('update-task', { id: task.splitID, start: split.start, end: split.end, showPer: 'resources' }).then((res) => {
            if (res.error) {
              notify("Move Task Error", "Please contact the administrator", 'error');
            }
          });

          return {
            ...t,
            start: task.start,
            end: task.end,
            duration: undefined,
            dates: task.dates.map((d, i) => {
              return {
                ...d,
                duration: undefined,
              };
            })
          };
        } else {
          return t;
        }
      }));
    }
  };

  const handleResize = (task) => {
    if (showPer === 'projects') {

      setTasks(tasks.map((t) => {
        if (t.id == task.id) {
          req('update-task', { id: task.id.replace('task-', ''), start: task.start, end: task.end, showPer: 'projects' }).then((res) => {
            if (res.error) {
              notify("Resize Task Error", "Please contact the administrator", 'error');
            }
          });

          return {
            ...task,
            duration: undefined
          };
        } else {
          return t;
        }
      }));
    } else {
      setTasks(tasks.map((t) => {
        if (t.id == task.id && t.dates.length == task.dates.length) {
          const split = task.dates.find((d) => d.id == task.splitID);
          req('update-task', { id: task.splitID, start: split.start, end: split.end, showPer: 'resources' }).then((res) => {
            if (res.error) {
              notify("Resize Task Error", "Please contact the administrator", 'error');
            }
          });

          return {
            ...task,
            duration: undefined,
            dates: task.dates.map((d, i) => {
              return {
                ...d,
                duration: undefined,
              };
            })
          };
        } else {
          return t;
        }
      }));
    }
  };

  const handleContextMenu = ({ task, event }) => {
    if (showPer === 'resources' && task.split.record === 'task') {
      setContextMenu({
        context: [ [
          {
            name: 'Edit', icon: { name: 'edit', type: 'rr' }, onClick: () => {
              go('data-management', {
                "form-modal": { show: 'projects-form', id: task.split.projectID },
                "selected-topic": "projects"
              });
            }
          }
        ] ],
        position: { x: event.clientX, y: event.clientY }
      });
    }
  };
  //#endregion

  //#region [computed] - filteredTasks
  const filteredTasks = useMemo(() => tasks?.filter((task) => {
    let isValid = true;

    if (showPer === 'projects') {
      isValid = isValid && isValidFilter(filters.interventions, task.interventionID);
      isValid = isValid && isValidFilter(filters.installations, task.installationID);
    } else if (showPer === 'resources') {
      isValid = isValid && isValidFilter(filters.offices, task.officeID);
      isValid = isValid && isValidFilter(filters.travels, task.travelID);
      isValid = isValid && isValidFilter(filters.resources, task.id);
      isValid = isValid && isValidFilter(filters.responsables, task.managerID);
    }

    return isValid;
  }).map((task) => {
    if(showPer === 'resources') {
      if(new Date(task.end) < new Date()) {
        task.status = filters.showCompleted ? 'completed' : 'hidden';
      } else {
        task.status = task.tentative === 'Y' ? 'dashed' : null;
      }

      task.dates = task.dates?.map((date) => {
        return new Date(date.end) < new Date() ? (
          { ...date, status: filters.showCompleted ? 'completed' : 'hidden' }
        ) : (
          { ...date, status: date.tentative === 'Y' ? 'dashed' : null }
        );
      });
    } else {
      task.status = task.tentative === 'Y' ? 'dashed' : null;
    }

    return task;
  }).filter((task) => {
    if(showPer === 'projects') {
      return filters.showCompleted ? true : new Date(task.end) > new Date();
    }

    return true;
  }), [ tasks, filters, showPer ]);
  //#endregion

  return (
    <SchedulerContext.Provider value={ {
      viewMode,
      setViewMode,
      showPer,
      setShowPer,
      filters,
      setFilters,
      tasks,
      filteredTasks,
      handleMove,
      handleResize,
      handleContextMenu,
      renderTableColumns,
      renderTableRow,
      calendar
    } }>
      <ViewPanel />
      
      <FilterPanel
        showPer={ showPer }
        filters={ filters }
        setFilters={ setFilters }
      />

      <SchedulerView />
    </SchedulerContext.Provider>
  );
};

//#region [comp] Scheduler - Page for managing tasks
const SchedulerView = () => {
  const {
    viewMode,
    showPer,
    tasks,
    filteredTasks,
    handleMove,
    handleResize,
    handleContextMenu,
    renderTableColumns,
    renderTableRow,
    calendar
  } = useScheduler();

  return (
    <div className="flex flex-col justify-center items-center w-full h-full">
      { tasks.length > 0 && (
        filteredTasks.length > 0 ? (
          <WeGantt
            workingHours={ 8 }
            viewMode={ viewMode }
            displayHolidays={ calendar.holidays }
            styles={ { holidayColor: '#f0f0f0', todayColor: '#AAAAFF' } }
            tasks={ filteredTasks }
            cellHeight={ CELL_HEIGHT }
            cellWidth={ CELL_WIDTH }
            fontSize={ FONT_SIZE }
            onMove={ handleMove }
            onResize={ handleResize }
            onTaskContextMenu={ handleContextMenu }
            TableColumns={ renderTableColumns }
            TableRow={ renderTableRow }
            TaskCell={ ({ task, splitTask }) => {
              if(showPer === 'resources'){
                if(!splitTask) return;

                const completed = Math.round((new Date() - new Date(splitTask.start)) / (new Date(splitTask.end) - new Date(splitTask.start)) * 100);

                return (
                  <span>
                    { splitTask.label } [{ (completed > 100 ? 100 : completed < 0 ? 0 : completed) + '%' }]
                  </span>
                );
              } else {
                if(!task) return;

                const completed = Math.round((new Date() - new Date(task.start)) / (new Date(task.end) - new Date(task.start)) * 100);

                return (
                  <span>
                    { task.name } [{ (completed > 100 ? 100 : completed < 0 ? 0 : completed) + '%' }]
                  </span>
                );
              }
            } }

          />
        ) : (
          <div className="flex justify-center items-center w-full h-full">
            <div className="flex flex-col justify-center items-center">
              With this filter, there are no tasks to display
            </div>
          </div>
        )
      ) }
    </div>
  );
};
//#endregion

export default Scheduler;
export { useScheduler };
