import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { useEffect, useState } from "react";
import { FormifyComboboxStyle, FormifyInputStyle, FormifySelectStyle } from "../../../../assets/styles";
import Flaticon from "../../../../components/Flaticon/Flaticon";
import { FormifyCombobox, FormifyComboboxOption, FormifySelect, FormifySelectOption } from "../../../../components/Formify/Formify";
import Modality from "../../../../components/Modality/Modality";
import req, { useRequest } from "../../../../modules/Request";
import * as session from "../../../../modules/Stash/Stash";
import { clsx } from "../../../../modules/Utilkit/Utilkit";
import { useNavigator } from "../../../components/Navigator";
import { useNotifyContext } from "../../../components/Notify";
import useActivities from "../hooks/useActivities";
import useInstallations from "../hooks/useInstallations";
import useInterventions from "../hooks/useInterventions";
import useJobs from "../hooks/useJobs";
import useResourcesExtend from "../hooks/useResourcesExtend";
import useWarrantyOptions from "../hooks/useWarrantyOptions";

const ProjectModal = ({ show, onClose, setTableData, id }) => {
  const { params } = useNavigator();
  const { notify } = useNotifyContext();
  const [ stage, setStage ] = useState(1); // [ 1, 2 ,3 ]
  const defaultData = {
    description: "",
    job: "",
    intervention: "",
    activity: "",
    installation: "",
    warranty: "Y",
    startDate: "",
    endDate: "",
    note: "",
    tentative: "N",
    numberOfResourcesA: 0,
    numberOfResourcesB: 0,
    numberOfTemporaryA: 0,
    numberOfTemporaryB: 0,
    resourcesA: [],
    resourcesB: [],
    temporaryA: [],
    temporaryB: []
  };

  const [ closeConfirm, setCloseConfirm ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ data, setData ] = useState(params[ "projects-form" ] || defaultData);

  useEffect(() => {
    if (params[ "projects-form" ]) {
      setData(params[ "projects-form" ]);
    }
  }, [ params ]);

  const jobs = useJobs({ show });
  const interventions = useInterventions({ show });
  const activities = useActivities({ show });
  const installations = useInstallations({ show });
  const resources = useResourcesExtend({ show, from: data.startDate, to: data.endDate, id });
  const warrantyOptions = useWarrantyOptions({ show });
  const [ tentativeList ] = useRequest('values', { payload: { type: 'TENTATIVE' }, onError: () => notify("Error", "Can't load tentative values", 'error') });

  const buttons = (
    stage === 1 ? [
      { name: "Cancel", disabled: isLoading, onClick: () => setCloseConfirm(true) },
      { name: "Next", styleSet: "success", disabled: isLoading, type: "submit" }
    ] : [
      { name: "Back", disabled: isLoading, onClick: () => setStage(1) },
      { name: isLoading ? ("Loading...") : (id ? "Update" : "Add"), type: "submit", styleSet: "success", disabled: isLoading }
    ]
  );

  const handleClose = () => {
    if (isLoading) return;
    setData(defaultData);
    setStage(1);
    session.delParam("projects-form");
    setCloseConfirm(false);
    onClose();
  };

  const resourceAFiltered = resourceMapping(resources?.filter(({ id, skillmatrix, officeRaw }) => {
    return !data.resourcesA?.includes(id)
      && skillmatrix?.find(({ activity }) => activity === data.activity)?.evaluatedLevel >= 2
      && skillmatrix?.filter(({ activity }) => activity === data.activity).length > 0
      && officeRaw !== 'TM';
  }), activities, data?.activity ?? undefined).sort((a, b) => a.skillmatrix?.find(({ activity }) => activity === data.activity).evaluatedLevel ?? -1 - b.skillmatrix?.find(({ activity }) => activity === data.activity).evaluatedLevel ?? -1);

  const resourceASelected = resourceMapping(resources?.filter(({ id }) => data.resourcesA?.includes(id)), activities, data?.activity ?? undefined);

  const resourceBFiltered = resourceMapping(resources?.filter(({ id, skillmatrix, officeRaw }) => {
    return !resourceAFiltered?.some((r) => r.id === id)
      && !data.resourcesB?.includes(id)
      && !data.resourcesA?.includes(id)
      && (skillmatrix?.length > 0 ? skillmatrix?.filter(({ activity }) => activity !== data.activity).length > 0 : true)
      && (officeRaw !== 'TM');
  }), activities);

  const resourceBSelected = resourceMapping(resources?.filter(({ id }) => data.resourcesB?.includes(id)), activities);

  const temporaryAFiltered = resourceMapping(resources?.filter(({ id, skillmatrix, officeRaw }) => {
    return !data.temporaryA?.includes(id)
      && skillmatrix?.find(({ activity }) => activity === data.activity)?.evaluatedLevel >= 2
      && skillmatrix?.filter(({ activity }) => activity === data.activity).length > 0
      && officeRaw === 'TM';
  }), activities, data?.activity ?? undefined).sort((a, b) => a.skillmatrix?.find(({ activity }) => activity === data.activity).evaluatedLevel ?? -1 - b.skillmatrix?.find(({ activity }) => activity === data.activity).evaluatedLevel ?? -1);

  const temporaryASelected = resourceMapping(resources?.filter(({ id }) => data.temporaryA?.includes(id)), activities);

  const temporaryBFiltered = resourceMapping(resources?.filter(({ id, skillmatrix, officeRaw }) => {
    return !temporaryAFiltered?.some((r) => r.id === id)
      && !data.temporaryB?.includes(id)
      && !data.temporaryA?.includes(id)
      && (skillmatrix?.length > 0 ? skillmatrix?.filter(({ activity }) => activity !== data.activity).length > 0 : true)
      && officeRaw === 'TM';
  }), activities);

  const temporaryBSelected = resourceMapping(resources?.filter(({ id }) => data.temporaryB?.includes(id)), activities);

  const handleDataChange = (key, value) => {
    setData(curr => {
      if (key === "numberOfResourcesA" && value < curr.resourcesA.length) {
        const newData = { ...curr, [ key ]: value, resourcesA: [] };
        session.addParam("projects-form", newData);
        return newData;
      } else if (key === "numberOfResourcesB" && value < curr.resourcesB.length) {
        const newData = { ...curr, [ key ]: value, resourcesB: [] };
        session.addParam("projects-form", newData);
        return newData;
      } else if (key === "numberOfTemporaryA" && value < curr.temporaryA.length) {
        const newData = { ...curr, [ key ]: value, temporaryA: [] };
        session.addParam("projects-form", newData);
        return newData;
      } else if (key === "numberOfTemporaryB" && value < curr.temporaryB.length) {
        const newData = { ...curr, [ key ]: value, temporaryB: [] };
        session.addParam("projects-form", newData);
        return newData;
      } else {
        const newData = { ...curr, [ key ]: value };
        session.addParam("projects-form", newData);
        return newData;
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (stage === 1) {
      setStage(2);
      return;
    }

    setIsLoading(true);

    if (id) {
      const res = await req("update-project", { id, ...data });

      if (res.success) {
        setTableData(curr => ({ ...curr, data: curr.data.map(item => item.id === res.data.id ? res.data : item) }));
        notify("Success", "Project has been updated", "success");
        handleClose();
      } else if (res.error === 'same-description') {
        notify("Error", "Project with the same description already exists", 'error');
      } else {
        notify("Error", "Please contact the administrator", 'error');
      }
    } else {
      const res = await req("insert-project", { ...data });

      if (res.success) {
        setTableData(curr => curr.data ? { ...curr, data: [ ...curr.data, res.data ] } : { ...curr, data: [ res.data ] });
        notify("Success", "Project has been added", "success");
        handleClose();
      } else if (res.error === 'same-description') {
        notify("Error", "Project with the same description already exists", 'error');

      } else {
        notify("Error", "Please contact the administrator", 'error');
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const res = await req("select-projects-raw", { id });

      console.log(res);

      if (res.success) {
        const res2 = await req("select-project-resources", { id });

        if (res2.success) {
          setData({
            ...res.data[ 0 ],
            resourcesA: res2.data.resourcesA,
            resourcesB: res2.data.resourcesB,
            temporaryA: res2.data.temporaryA,
            temporaryB: res2.data.temporaryB
          });
        } else {
          notify("Error", "Please contact the administrator", 'error');
          handleClose();
        }
      } else {
        notify("Error", "Please contact the administrator", 'error');
        handleClose();
      }

      setIsLoading(false);
    };

    if (id && show) fetchData();
  }, [ id ]);

  return (
    <Modality show={ show } onClose={ () => setCloseConfirm(true) } onSubmit={ handleSubmit } width="920px" buttons={ buttons }>
      <legend className="text-xl">
        { id ? "Edit Project" : "New Project" } <span className="font-semibold">{ data.description || '...' }</span>
      </legend>

      <Modality
        show={ closeConfirm }
        label="Warning!"
        icon={ { name: "triangle-warning", type: "br", className: "text-red-600" } }
        onClose={ () => setCloseConfirm(false) }
        buttons={ [
          { name: "No", onClick: () => setCloseConfirm(false) },
          { name: "Yes", styleSet: 'error', onClick: handleClose }
        ] }
      >
        Are you sure you want to close this form? All unsaved changes will be lost.
      </Modality>

      { stage === 1 ? <>
        <div className="flex w-full space-x-2">
          { /* NAME */ }
          <fieldset className="flex flex-col w-full" disabled={ isLoading }>
            <label htmlFor="name">Name</label>
            <input
              required={ true }
              name="name"
              className={ FormifyInputStyle }
              autoComplete="off"
              value={ data.description }
              onInput={ (e) => handleDataChange("description", e.target.value) }
            />
          </fieldset>

          { /* JOB */ }
          <fieldset className="flex flex-col w-full" disabled={ isLoading }>
            <label htmlFor="job">Job</label>
            <FormifyCombobox
              required={ true }
              className={ FormifyComboboxStyle }
              name="job"
              autoComplete="off"
              value={ data.job }
              onChange={ (val) => handleDataChange("job", val) }
            >
              { jobs.map(({ label, value }) => (
                <FormifyComboboxOption
                  key={ value }
                  className={ clsx(
                    "w-full h-full flex p-1 space-x-2 hover:space-x-0 items-center overflow-hidden cursor-pointer",
                    "hover:items-start hover:flex-col duration-75 [&:hover>.label]:text-wrap [&:hover>.hashtags]:flex-wrap",
                    value === data.job ? "bg-green-200" : "hover:bg-gray-200 data-[focus]:bg-gray-200"
                  ) }
                  value={ value }
                  label={ label }
                >
                  <div className="flex space-x-2 label  duration-75 text-nowrap">
                    <Flaticon name="check" type="rr" size={ 18 } className={ clsx("text-green-800", value === data.job ? "visible" : "invisible") } />
                    <span className="label">{ label }</span>
                  </div>
                </FormifyComboboxOption>
              )) }
            </FormifyCombobox>
          </fieldset>

          { /* INTERVENTION TYPE */ }
          <fieldset className="flex flex-col w-full" disabled={ isLoading }>
            <label htmlFor="invervention">Intervention Type</label>
            <FormifyCombobox
              className={ FormifyComboboxStyle }
              name="intervention"
              required={ true }
              autoComplete="off"
              value={ data.intervention }
              onChange={ (val) => handleDataChange("intervention", val) }
              options={ interventions }
            >
              { interventions.map(({ label, value }) => (
                <FormifyComboboxOption
                  key={ value }
                  className={ clsx(
                    "w-full h-full flex p-1 space-x-2 hover:space-x-0 items-center overflow-hidden cursor-pointer",
                    "hover:items-start hover:flex-col duration-75 [&:hover>.label]:text-wrap [&:hover>.hashtags]:flex-wrap",
                    value === data.intervention ? "bg-green-200" : "hover:bg-gray-200 data-[focus]:bg-gray-200"
                  ) }
                  value={ value }
                  label={ label }
                >
                  <div className="flex space-x-2 label  duration-75 text-nowrap">
                    <Flaticon name="check" type="rr" size={ 18 } className={ clsx("text-green-800", value === data.intervention ? "visible" : "invisible") } />
                    <span className="label">{ label }</span>
                  </div>
                </FormifyComboboxOption>
              )) }
            </FormifyCombobox>
          </fieldset>
        </div>

        <div className="flex w-full space-x-2">
          { /* ACTIVITY TYPE */ }
          <fieldset className="flex flex-col w-full" disabled={ isLoading }>
            <label htmlFor="activity">Activity Type</label>
            <FormifyCombobox
              name="activity"
              className={ FormifyComboboxStyle }
              required={ true }
              autoComplete="off"
              value={ data.activity }
              onChange={ (val) => handleDataChange("activity", val) }
              options={ activities }
            >
              { activities.map(({ label, value }) => (
                <FormifyComboboxOption
                  key={ value }
                  className={ clsx(
                    "w-full h-full flex p-1 space-x-2 hover:space-x-0 items-center overflow-hidden cursor-pointer",
                    "hover:items-start hover:flex-col duration-75 [&:hover>.label]:text-wrap [&:hover>.hashtags]:flex-wrap",
                    value === data.activity ? "bg-green-200" : "hover:bg-gray-200 data-[focus]:bg-gray-200"
                  ) }
                  value={ value }
                  label={ label }
                >
                  <div className="flex space-x-2 label  duration-75 text-nowrap">
                    <Flaticon name="check" type="rr" size={ 18 } className={ clsx("text-green-800", value === data.activity ? "visible" : "invisible") } />
                    <span className="label">{ label }</span>
                  </div>
                </FormifyComboboxOption>
              )) }
            </FormifyCombobox>
          </fieldset>

          { /* INSTALLATION */ }
          <fieldset className="flex flex-col w-full" disabled={ isLoading }>
            <label htmlFor="installation">Installation</label>
            <FormifyCombobox
              required={ true }
              name="installation"
              autoComplete="off"
              className={ FormifyComboboxStyle }
              value={ data.installation }
              onChange={ (val) => handleDataChange("installation", val) }
            >
              { installations.map(({ label, value, hashTags }) => (
                <FormifyComboboxOption
                  key={ value }
                  className={ clsx(
                    "w-full h-full flex p-1 space-x-2 hover:space-x-0 items-center overflow-hidden cursor-pointer",
                    "hover:items-start hover:flex-col duration-75 [&:hover>.label]:text-wrap [&:hover>.hashtags]:flex-wrap",
                    value === data.installation ? "bg-green-200" : "hover:bg-gray-200 data-[focus]:bg-gray-200"
                  ) }
                  value={ value }
                  label={ label }
                  hashTags={ hashTags }
                >
                  <div className="flex space-x-2 label  duration-75 text-nowrap">
                    <Flaticon name="check" type="rr" size={ 18 } className={ clsx("text-green-800", value === data.installation ? "visible" : "invisible") } />
                    <span className="label">{ label }</span>
                  </div>
                  <div className="hashtags text-nowrap">
                    { hashTags?.map(tag => (
                      <span key={ tag }>
                        #<span className="text-gray-500">{ tag }</span>
                      </span>
                    )) }
                  </div>
                </FormifyComboboxOption>
              )) }
            </FormifyCombobox>
          </fieldset>

          { /* WARRANTY */ }
          <fieldset className="flex flex-col w-full" disabled={ isLoading }>
            <label htmlFor="warranty">Warranty</label>
            <FormifySelect
              name="warranty"
              className={ FormifySelectStyle }
              required={ true }
              value={ data.warranty }
              onChange={ (val) => handleDataChange("warranty", val) }
            >
              { warrantyOptions.map(({ label, value }) => (
                <FormifySelectOption
                  key={ value }
                  className={ clsx(
                    "p-1 flex items-center overflow-hidden cursor-pointer hover:items-start hover:flex-col duration-75 [&:hover>.label]:text-wrap",
                    value === data.warranty ? "bg-green-200" : "hover:bg-gray-200 data-[focus]:bg-gray-200"
                  ) }
                  value={ value }
                  label={ label }
                >
                  <div className="flex space-x-2 label  duration-75 text-nowrap">
                    <Flaticon name="check" type="rr" size={ 18 } className={ clsx("text-green-800", value === data.warranty ? "visible" : "invisible") } />
                    <span className="label">{ label }</span>
                  </div>
                </FormifySelectOption>
              )) }
            </FormifySelect>
          </fieldset>
        </div>

        { /* ESTIMATED START */ }
        <div className="flex w-full space-x-2">
          <fieldset className="flex flex-col w-full" disabled={ isLoading }>
            <label htmlFor="startDate">Estimated Start</label>
            <input
              required={ true }
              className={ FormifyInputStyle }
              name="startDate"
              type="date"
              max={ data.endDate }
              autoComplete="off"
              value={ data.startDate }
              onInput={ (e) => handleDataChange("startDate", e.target.value) }
            />
          </fieldset>

          { /* ESTIMATED END */ }
          <fieldset className="flex flex-col w-full" disabled={ !data.startDate || isLoading }>
            <label htmlFor="endDate">Estimated End</label>
            <input
              required={ true }
              className={ FormifyInputStyle }
              name="endDate"
              type="date"
              min={ data.startDate }
              autoComplete="off"
              value={ data.endDate }
              onInput={ (e) => handleDataChange("endDate", e.target.value) }
            />
          </fieldset>


          { /* Tentative */ }
          <fieldset className="flex flex-col w-full" disabled={ !data.startDate || isLoading }>
            <label htmlFor="endDate">Tentative</label>
            <FormifySelect required
              name="tentative"
              className={ FormifySelectStyle }
              value={ data.tentative }
              onChange={ (val) => handleDataChange("tentative", val) }
            >
              { tentativeList.map(({ label, value }) => (
                <FormifySelectOption
                  key={ value }
                  className={ clsx(
                    "p-1 flex items-center overflow-hidden cursor-pointer hover:items-start hover:flex-col duration-75 [&:hover>.label]:text-wrap",
                    value === data.tentative ? "bg-green-200" : "hover:bg-gray-200 data-[focus]:bg-gray-200"
                  ) }
                  value={ value }
                  label={ label }
                >
                  <div className="flex space-x-2 label  duration-75 text-nowrap">
                    <Flaticon name="check" type="rr" size={ 18 } className={ clsx("text-green-800", value === data.tentative ? "visible" : "invisible") } />
                    <span className="label">{ label }</span>
                  </div>
                </FormifySelectOption>
              )) }
            </FormifySelect>
          </fieldset>
        </div>

        { /* Note */ }
        <fieldset className="flex flex-col w-full">
          <label htmlFor="note">Note</label>
          <textarea
            name="note"
            className={ FormifyInputStyle }
            disabled={ isLoading }
            value={ data.note }
            onInput={ (e) => handleDataChange("note", e.target.value) }
            rows={ 4 }
          />
        </fieldset>
      </> : <>
        <div className="flex w-full space-x-2 mb-3">
          { /* Number Of Resources A */ }
          <fieldset className="flex flex-col w-full">
            <label htmlFor="resourcesA">Resources A</label>
            <input
              required={ true }
              className={ FormifyInputStyle }
              name="resourcesA"
              type="number"
              min={ 0 }
              step={ 1 }
              value={ data.numberOfResourcesA }
              onInput={ (e) => handleDataChange("numberOfResourcesA", e.target.value) }
            />
          </fieldset>

          { /* Number Of Resources B */ }
          <fieldset className="flex flex-col w-full">
            <label htmlFor="resourcesB">Resources B</label>
            <input
              required={ true }
              className={ FormifyInputStyle }
              name="resourcesB"
              type="number"
              min={ 0 }
              step={ 1 }
              value={ data.numberOfResourcesB }
              onInput={ (e) => handleDataChange("numberOfResourcesB", e.target.value) }
            />
          </fieldset>

          { /* Number Of Temporary A */ }
          <fieldset className="flex flex-col w-full">
            <label htmlFor="temporaryA">Temporary A</label>
            <input
              required={ true }
              className={ FormifyInputStyle }
              name="temporaryA"
              type="number"
              min={ 0 }
              step={ 1 }
              value={ data.numberOfTemporaryA }
              onInput={ (e) => handleDataChange("numberOfTemporaryA", e.target.value) }
            />
          </fieldset>

          { /* Number Of Temporary B */ }
          <fieldset className="flex flex-col w-full">
            <label htmlFor="temporaryB">Temporary B</label>
            <input
              required={ true }
              className={ FormifyInputStyle }
              name="temporaryB"
              type="number"
              min={ 0 }
              step={ 1 }
              value={ data.numberOfTemporaryB }
              onInput={ (e) => handleDataChange("numberOfTemporaryB", e.target.value) }
            />
          </fieldset>
        </div>

        <BookSelector
          from={ data.startDate }
          to={ data.endDate }
          categories={ [
            {
              id: 'resourcesA',
              label: 'Resources A (' + data.resourcesA?.length + '/' + data.numberOfResourcesA + ')',
              data: resourceAFiltered,
              selected: resourceASelected,
              disabled: data.numberOfResourcesA <= 0,
              correct: data.numberOfResourcesA <= data.resourcesA?.length,
              onSelect: (id) => {
                if (data.numberOfResourcesA <= data.resourcesA.length) return notify("Error", "Number of Resources A exceeded", 'error');
                handleDataChange("resourcesA", [ ...data.resourcesA, id ]);
              },
              onUnselect: (id) => { handleDataChange("resourcesA", data.resourcesA.filter(item => item !== id)); }
            },
            {
              id: 'resourcesB',
              label: 'Resources B (' + data.resourcesB?.length + '/' + data.numberOfResourcesB + ')',
              data: resourceBFiltered,
              selected: resourceBSelected,
              disabled: data.numberOfResourcesB <= 0,
              correct: data.numberOfResourcesB <= data.resourcesB?.length,
              onSelect: (id) => {
                if (data.numberOfResourcesB <= data.resourcesB.length) return notify("Error", "Number of Resources B exceeded", 'error');
                handleDataChange("resourcesB", [ ...data.resourcesB, id ]);
              },
              onUnselect: (id) => { handleDataChange("resourcesB", data.resourcesB.filter(item => item !== id)); }
            },
            {
              id: 'temporaryA',
              label: 'Temporary A (' + data.temporaryA?.length + '/' + data.numberOfTemporaryA + ')',
              data: temporaryAFiltered,
              selected: temporaryASelected,
              disabled: data.numberOfTemporaryA <= 0,
              correct: data.numberOfTemporaryA <= data.temporaryA?.length,
              onSelect: (id) => {
                if (data.numberOfTemporaryA <= data.temporaryA.length) return notify("Error", "Number of Temporary A exceeded", 'error');
                handleDataChange("temporaryA", [ ...data.temporaryA, id ]);
              },
              onUnselect: (id) => { handleDataChange("temporaryA", data.temporaryA.filter(item => item !== id)); }
            },
            {
              id: 'temporaryB',
              label: 'Temporary B (' + data.temporaryB?.length + '/' + data.numberOfTemporaryB + ')',
              data: temporaryBFiltered,
              selected: temporaryBSelected,
              disabled: data.numberOfTemporaryB <= 0,
              correct: data.numberOfTemporaryB <= data.temporaryB?.length,
              onSelect: (id) => {
                if (data.numberOfTemporaryB <= data.temporaryB.length) return notify("Error", "Number of Temporary B exceeded", 'error');
                handleDataChange("temporaryB", [ ...data.temporaryB, id ]);
              },
              onUnselect: (id) => { handleDataChange("temporaryB", data.temporaryB.filter(item => item !== id)); }
            }
          ] }
        />
      </> }
    </Modality>
  );
};

const BookSelector = ({ categories, from, to }) => {
  const [ queryToBeAssigned, setQueryToBeAssigned ] = useState("");
  const [ queryAssigned, setQueryAssigned ] = useState("");

  return <TabGroup>
    <TabList className="w-full flex rounded-md">
      { categories.map((tab) => (
        <Tab
          key={ tab.id }
          disabled={ tab.disabled }
          className={ clsx(
            "data-[selected]:bg-white disabled:text-gray-500 data-[selected]:font-semibold py-1 border-b",
            "border-l last:border-r border-t outline-none bg-gray-100 data-[selected]:border-b-white",
            "data-[selected]:border-t flex-1 first:rounded-tl-md last:rounded-tr-md cursor-pointer",
            tab.correct ? "text-green-400" : ""
          ) }
        >
          { tab.label }
        </Tab>
      )) }
    </TabList>
    <TabPanels className="h-[400px] border-x border-b rounded-b-md">
      { categories.map((tab) => (
        <TabPanel key={ tab.id } className="w-full flex h-full rounded-b-md">
          <div className="flex-1 rounded-bl-md border-r border-gray-300 overflow-y-auto divide-y divide-gray-200">
            <div className="flex px-2 py-1 items-center">
              <label htmlFor="toBeAssigned" className="flex-1">Resource to be assigned</label>
              <input
                className={ FormifyInputStyle }
                name="toBeAssigned"
                type="text"
                placeholder="Search..."
                value={ queryToBeAssigned }
                onInput={ (e) => setQueryToBeAssigned(e.target.value) }
              />
            </div>
            { tab.data?.map(({ label, id, hashTags, tasks }) => {
              const splitQ = queryToBeAssigned.toLowerCase().split(' ');

              const validated = splitQ.every(q => {
                const lowerLabel = label.toLowerCase();
                const mappedHashtags = hashTags?.map(tag => tag.toLowerCase()) ?? [];

                if (q.startsWith('#') && mappedHashtags?.length > 0) {
                  const hashtagsValidated = mappedHashtags?.some(tag => tag.includes(q.slice(1)));
                  const labelValidated = lowerLabel.includes(q);

                  return hashtagsValidated || labelValidated;
                } else {
                  return lowerLabel.includes(q);
                }
              });


              if (queryToBeAssigned === "" || validated) {
                const filteredHashTags = hashTags?.filter((tag) => {
                  return queryToBeAssigned.toLowerCase().split(' ').some(q => q.startsWith('#') && tag.toLowerCase().includes(q.slice(1)))
                    || queryToBeAssigned === ""
                    || !queryToBeAssigned.includes("#");
                }).map((tag) => (
                  <span key={ tag }>
                    #<span className={ "text-gray-500" }>{ tag }</span>
                  </span>
                ));

                return (
                  <div
                    key={ id }
                    className="flex items-center space-x-2 hover:bg-gray-200 cursor-pointer px-2 last:border-b py-0.5"
                    onClick={ () => tab.onSelect(id) }
                  >
                    <div className="[&:hover_.tasks]:flex relative">
                      { tasks?.length > 0
                        && tasks?.filter((task) => from >= task.start && to <= task.end).length > 0 ? (
                        <Flaticon name="calendar-xmark" type="rr" size={ 18 } className="text-red-600" />
                      ) : tasks?.length > 0 ? (
                        <Flaticon name="calendar-clock" type="rr" size={ 18 } className="text-orange-600" />
                      ) : (
                        <Flaticon name="calendar-check" type="rr" size={ 18 } className="text-green-600" />
                      ) }

                      { tasks?.length > 0 && (
                        <div className="hidden min-w-[220px] tasks bg-white flex-col z-[60] absolute top-full left-0 p-2 rounded-md shadow-[0_0_4px_0_#00000050]">
                          <div className="text-xs text-gray-500 divide-y divide-gray-300">
                            { tasks?.map(({ label, start, end }) => (
                              <div className="w-full flex flex-col py-1 last:pb-0 first:pt-0">
                                <span className="text-nowrap text-gray-800">{ label }</span>
                                <span className="text-nowrap text-black font-medium" key={ start + end }>
                                  { start.split('-').reverse().join('/') } - { end.split('-').reverse().join('/') }
                                </span>
                              </div>
                            )) }
                          </div>
                        </div>
                      ) }
                    </div>
                    <div className="flex flex-col flex-1 relative">
                      <div className="p-0">{ label }</div>
                      <div className="[&:hover_.hashtags-hidden]:visible">
                        <div className="hashtags flex space-x-1 text-xs text-gray-800 text-nowrap flex-wrap overflow-y-hidden max-h-8">
                          { filteredHashTags ?? "" }
                        </div>
                        <div
                          className={ clsx(
                            "hashtags-hidden invisible pointer-events-none p-1 box-content bg-white rounded-md shadow-[0_0_4px_0_#00000080]",
                            "z-50 absolute top-5 -left-1 flex-wrap flex space-x-1 text-xs text-gray-800",
                            hashTags?.length <= 0 && "hidden"
                          ) }
                        >
                          { filteredHashTags ?? "" }
                        </div>
                      </div>
                    </div>
                  </div>

                );
              }
            }) }
          </div>
          <div className=" flex-1 rounded-br-md overflow-y-auto divide-y divide-gray-200">
            <div className="flex px-2 py-1 items-center">
              <label htmlFor="assigned" className="flex-1">Assigned Resources</label>
              <input
                className={ FormifyInputStyle }
                name="assigned"
                type="text"
                placeholder="Search..."
                value={ queryAssigned }
                onInput={ (e) => setQueryAssigned(e.target.value) }
              />
            </div>
            { tab.selected?.map(({ label, id, hashTags, tasks }) => {
              const splitQ = queryAssigned.toLowerCase().split(' ');

              const validated = queryAssigned ? splitQ.every(q => {
                const lowerLabel = label.toLowerCase();
                const mappedHashtags = hashTags?.map(tag => tag.toLowerCase()) ?? [];

                if (q.startsWith('#') && mappedHashtags?.length > 0) {
                  const hashtagsValidated = mappedHashtags?.some(tag => tag.includes(q.slice(1)));
                  const labelValidated = lowerLabel.includes(q);

                  return hashtagsValidated || labelValidated;
                } else {
                  return lowerLabel.includes(q);
                }
              }) : true;

              if (queryAssigned === "" || validated) {
                const filteredHashTags = hashTags?.filter((tag) => {
                  return queryAssigned.toLowerCase().split(' ').some(q => q.startsWith('#') && tag.toLowerCase().includes(q.slice(1)))
                    || queryAssigned === ""
                    || !queryAssigned.includes("#");
                }).map(tag => (
                  <span key={ tag }>
                    #<span className="text-gray-500">{ tag }</span>
                  </span>
                ));

                return (
                  <div key={ id } className="flex items-center space-x-2 hover:bg-gray-200 cursor-pointer px-2 last:border-b py-0.5" onClick={ () => tab.onUnselect(id) }>
                    <div>
                      { tasks?.length > 0
                        && tasks?.filter((task) => from >= task.start && to <= task.end).length > 0 ? (
                        <Flaticon name="calendar-xmark" type="rr" size={ 18 } className="text-red-600" />
                      ) : tasks?.length > 0 ? (
                        <Flaticon name="calendar-clock" type="rr" size={ 18 } className="text-orange-600" />
                      ) : (
                        <Flaticon name="calendar-check" type="rr" size={ 18 } className="text-green-600" />
                      ) }
                    </div>
                    <div className="flex flex-col flex-1 relative">
                      <div className="p-0">{ label }</div>
                      <div className="[&:hover_.hashtags-hidden]:visible">
                        <div className="hashtags flex space-x-1 flex-wrap text-xs text-gray-800 overflow-y-hidden max-h-8">
                          { filteredHashTags ?? "" }
                        </div>
                        <div
                          className={ clsx(
                            "hashtags-hidden invisible pointer-events-none p-1 box-content bg-white rounded-md shadow-[0_0_4px_0_#00000050]",
                            "z-50 absolute top-5 -left-1 flex-wrap flex space-x-1 text-xs text-gray-800",
                            hashTags?.length <= 0 && "hidden"
                          ) }
                        >
                          { filteredHashTags ?? "" }
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            }) }
          </div>
        </TabPanel>
      )) }
    </TabPanels>
  </TabGroup>;
};

const resourceMapping = (resources, activities, mainSkill = undefined) => {
  return resources.map(({ id, fullname, skillmatrix, tasks }) => ({
    id,
    label: fullname,
    tasks: tasks,
    hashTags: activities.filter(({ value }) => {
      if (mainSkill) {
        return mainSkill === value && skillmatrix.some(({ activity }) => activity === mainSkill);
      } else {
        return skillmatrix?.some(({ activity }) => {
          return activity === value;
        });
      }
    }).map(({ label, value }) => {
      const name = label?.split(' ').map((item) => item[ 0 ]?.toUpperCase() + (item.length > 1 ? item.slice(1) : '')).join('');
      const skill = skillmatrix.find(({ activity }) => activity === value);
      const rate = '[' + skill.expectedLevel + '/' + skill.evaluatedLevel + ']';
      return name + rate;
    })
  }));
};

export default ProjectModal;