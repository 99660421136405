import { useEffect, useState } from "react";
import * as session from "../../../../modules/Stash/Stash";
import req from "../../../../modules/Request";
import { useNotifyContext } from "../../../components/Notify";
import useActivities from "../hooks/useActivities";
import { FormifyLevel } from "../../../../components/Formify/Formify";
import { clsx } from "../../../../modules/Utilkit/Utilkit";
import Modality from "../../../../components/Modality/Modality";


const SkillMatrixModal = ({ show, onClose, id, data }) => {
  const { notify } = useNotifyContext();

  const [ isLoading, setIsLoading ] = useState(false);
  const [ formData, setFormData ] = useState({});
  const [ resourceName, setResourceName ] = useState("...");

  const buttons = [
    { name: "Cancel", disabled: isLoading, onClick: () => handleClose() },
    { name: data?.office !== 'Temporary' ? "Can't Edit" : isLoading ? "Loading..." : "Update", type: "submit", styleSet: "success", disabled: isLoading || data?.office !== 'Temporary' }
  ];

  const activities = useActivities({ show });

  const handleClose = () => {
    if (isLoading) return;
    setFormData({});
    setResourceName("...");
    session.delParam("clients-form");
    onClose();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (id) {
      const skillmatrix = Object.keys(formData).reduce((acc, key) => [
        ...acc,
        [ +key, formData[ key ].evaluatedLevel, formData[ key ].expectedLevel ]
      ], []);
      const res = await req("sync-skillmatrix", { resource: id, skillmatrix });

      if (res.success) {
        notify("Success", "Skillmatrix has been updated", "success");
      } else {
        notify("Error", "Please contact the administrator", 'error');
      }
    }

    setIsLoading(false);
  };

  const handleDataChange = (key, value) => {
    setFormData(curr => {
      const newData = { ...curr, [ key ]: value };
      session.addParam("clients-form", newData);
      return newData;
    });
  };

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);

      const res = await req("select-skillmatrix", { resource: id });
      const resResource = (await req("select-resources", { id }));

      if (res.success && resResource.success) {
        setResourceName(resResource.data.fullname);

        if (res.data) {
          setFormData(res.data.reduce((acc, item) => ({ ...acc, [ item.activity ]: { expectedLevel: +item.expectedLevel, evaluatedLevel: +item.evaluatedLevel } }), {}));
        }
      } else {
        notify("Error", "Please contact the administrator", 'error');
        handleClose();
      }

      setIsLoading(false);
    };

    if (id && show) fetch();
  }, [ id ]);

  return (
    <Modality show={ show } onClose={ handleClose } onSubmit={ handleSubmit } width="920px" buttons={ buttons }>
      <div className="mb-2 text-xl">Skill Matrix of <span className="font-semibold">{ resourceName }</span></div>

      <div className="w-full flex justify-end border-b py-1 space-x-[84px]">
        <span className="font-semibold ">Expected Level</span>
        <span className="font-semibold">Evaluated Level</span>
      </div>

      { activities.length > 0 && activities.filter(item => item.label !== 'Altro').map(({ label, value }) => (
        <div className="flex w-full space-x-2 justify-between py-[3px] border-b" key={ value }>
          <span>{ label }</span>
          <div className="flex space-x-8">
            <FormifyLevel
              className={ clsx(
                "flex flex-1 cursor-pointer justify-center items-center w-6 h-6 rounded-md",
                "font-semibold text-gray-800 shadow-[0_0_2px_0_#00000060]",
                isLoading || data?.office !== "Temporary" ? (
                  "bg-gray-200 text-gray-800 data-[checked]:bg-blue-300 data-[checked]:text-white"

                ) : (
                  "bg-white data-[checked]:bg-blue-600 data-[checked]:text-white"
                )
              ) }
              from={ 0 }
              to={ 5 }
              step={ 1 }
              value={ formData[ value ]?.expectedLevel ?? 0 }
              onChange={ (val) => handleDataChange(value, formData[ value ] ? { ...formData[ value ], expectedLevel: val } : { expectedLevel: val, evaluatedLevel: 0 }) }
              disabled={ isLoading || data?.office !== "Temporary" }
            />

            <FormifyLevel
              className={ clsx(
                "flex flex-1 cursor-pointer justify-center items-center w-6 h-6 rounded-md font-semibold text-gray-800 shadow-[0_0_2px_0_#00000060]",
                isLoading || data?.office !== "Temporary" ? (
                  "bg-gray-200 text-gray-800 data-[checked]:bg-blue-300 data-[checked]:text-white"

                ) : (
                  "bg-white data-[checked]:bg-blue-600 data-[checked]:text-white"
                )
              ) }
              from={ 0 }
              to={ 5 }
              step={ 1 }
              value={ formData[ value ]?.evaluatedLevel ?? 0 }
              onChange={ (val) => handleDataChange(value, formData[ value ] ? { ...formData[ value ], evaluatedLevel: val } : { expectedLevel: 0, evaluatedLevel: val }) }
              disabled={ isLoading || data?.office !== "Temporary" }
            />
          </div>
        </div>
      )) }
    </Modality>
  );
};

export default SkillMatrixModal;