import { useEffect, useState } from "react";
import { useNavigator } from "../../../components/Navigator";
import * as session from "../../../../modules/Stash/Stash";
import req, { useReq } from "../../../../modules/Request";
import { useNotifyContext } from "../../../components/Notify";
import useClients from "../hooks/useClients";
import useNations from "../hooks/useNations";
import { useAuth } from "../../../components/Auth";
import Modality from "../../../../components/Modality/Modality";
import { FormifyComboboxStyle, FormifyInputStyle } from "../../../../assets/styles";
import { FormifyCombobox, FormifyComboboxOption } from "../../../../components/Formify/Formify";
import { clsx } from "../../../../modules/Utilkit/Utilkit";
import Flaticon from "../../../../components/Flaticon/Flaticon";


const InstallationModal = ({ show, onClose, setTableData, id }) => {
  const { params } = useNavigator();
  const { notify } = useNotifyContext();
  const { userData: { permissions } } = useAuth();
  const defaultData = { description: "", city: "", nation: "", client: "" };

  const [ isLoading, setIsLoading ] = useState(false);
  const [ data, setData ] = useState(params[ "installations-form" ] ?? defaultData);
  const hasAccess = permissions.includes("SSP_AM_DATAMAN");
  const submitButtonLabel = hasAccess ? (isLoading ? "Loading..." : id ? "Edit" : "Add") : "No Access";

  const buttons = [
    { name: "Cancel", disabled: isLoading, onClick: () => handleClose() },
    { name: submitButtonLabel, type: "submit", styleSet: "success", disabled: isLoading || !hasAccess }
  ];

  
  const [ clients ] = useReq({
    url: "clients",
    handleError: (error) => notify("Error", error, "error"),
    handleSelect: async () => {
      if(show) {
        const res = await req("select-clients");

        if(res.success) {
          res.data = res.data.map(item => ({ ...item, value: item.id, label: item.description + ' - ' + item.notes }));
        }

        return res;
      }
    }
  }, [ show ]);

  // useClients({ show });
  const [ nations ] = useReq({
    url: "nations",
    handleError: (error) => notify("Error", error, "error"),
    handleSelect: async () => {
      if(show) {
        const res = await req("select-values", { type: "NAZIONE" });

        return res;
      }
    }
  }, [ show ])

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (id) {
      const res = await req("update-installation", { ...data });

      if (res.success) {
        setTableData(curr => ({ ...curr, data: curr.data.map(item => item.id === res.data.id ? res.data : item) }));
        notify("Success", "Installation has been updated", "success");
        handleClose();
      } else {
        if (res.error === 'access-danied') {
          notify("Error", "You do not have the necessary permissions", 'error');
        } else {
          notify("Error", "Please contact the administrator", 'error');
        }
      }
    } else {
      const res = await req("insert-installation", { ...data });

      if (res.success) {
        setTableData(curr => curr.data ? { ...curr, data: [ ...curr.data, res.data ] } : { ...curr, data: [ res.data ] });
        notify("Success", "Installation has been added", "success");
        handleClose();
      } else {
        if (res.error === 'access-danied') {
          notify("Error", "You do not have the necessary permissions", 'error');
        } else {
          notify("Error", "Please contact the administrator", 'error');
        }
      }
    }

    setIsLoading(false);
  };

  const handleClose = () => {
    if (isLoading) return;
    setData(defaultData);
    session.delParam("installations-form");
    onClose();
  };

  const handleDataChange = (key, value) => {
    setData(curr => {
      const newData = { ...curr, [ key ]: value };
      session.addParam("installations-form", newData);
      return newData;
    });
  };

  useEffect(() => {
    const fetchJobs = async () => {
      setIsLoading(true);

      const res = await req("select-installations-raw", { id: id });

      if (res.success) {
        setData(res.data[ 0 ]);
      } else {
        notify("Error", "Please contact the administrator", 'error');
        handleClose();
      }

      setIsLoading(false);
    };

    if (id && show) fetchJobs();
  }, [ id ]);

  return (
    <Modality show={ show } onClose={ handleClose } onSubmit={ handleSubmit } width="320px" buttons={ buttons }>
      { /* TITLE */ }
      <h1 className="text-xl">New Installation</h1>

      { /* DESCRIPTION */ }
      <div className="flex flex-col w-full">
        <label htmlFor="name">Name</label>
        <input
          name="name"
          className={ FormifyInputStyle }
          required={ true }
          disabled={ isLoading || !hasAccess }
          value={ data.description }
          onInput={ (e) => handleDataChange("description", e.target.value) }
        />
      </div>

      { /* CITY */ }
      <div className="flex flex-col w-full">
        <label>City</label>
        <input
          name="city"
          className={ FormifyInputStyle }
          required={ true }
          disabled={ isLoading || !hasAccess }
          value={ data.city }
          onInput={ (e) => handleDataChange("city", e.target.value) }
        />
      </div>

      { /* NATION */ }
      <div className="flex flex-col w-full">
        <label htmlFor="nation">Nation</label>
        <FormifyCombobox
          name="nation"
          className={ FormifyComboboxStyle }
          required={ true }
          disabled={ isLoading || !hasAccess }
          value={ data.nation }
          onChange={ (val) => handleDataChange("nation", val) }
        >
          { nations.map(({ value, label }) => (
            <FormifyComboboxOption
              className={ clsx(
                "w-full h-full flex py-1 space-x-2 hover:space-x-0 overflow-hidden items-center data-[focus]:bg-gray-200 cursor-pointer",
                "hover:items-start hover:flex-col duration-75 [&:hover>.label]:text-wrap [&:hover>.hashtags]:flex-wrap overflow-hidden text-ellipsis",
                value === data.responsable ? "bg-green-200" : "hover:bg-gray-200 data-[focus]:bg-gray-200"
              ) }
              key={ value }
              value={ value }
              label={ label }
            >
              <div className="flex space-x-2 label  duration-75 text-nowrap">
                <Flaticon name="check" type="rr" size={ 18 } className={ clsx("text-green-800", value === data.nation ? "visible" : "invisible") } />
                <span className="label">{ label }</span>
              </div>
            </FormifyComboboxOption>
          )) }
        </FormifyCombobox>
      </div>

      { /* CLIENT */ }
      <div className="flex flex-col w-full">
        <label htmlFor="client">Client</label>
        <FormifyCombobox
          name="client"
          className={ FormifyComboboxStyle }
          required={ true }
          disabled={ isLoading || !hasAccess }
          value={ data.client }
          onChange={ (val) => handleDataChange("client", val) }
        >
          { clients.map(({ value, label }) => (
            <FormifyComboboxOption
              className={ clsx(
                "w-full h-full flex py-1 space-x-2 hover:space-x-0 overflow-hidden items-center data-[focus]:bg-gray-200 cursor-pointer",
                "hover:items-start hover:flex-col duration-75 [&:hover>.label]:text-wrap [&:hover>.hashtags]:flex-wrap overflow-hidden text-ellipsis"
              ) }
              key={ value }
              value={ value }
              label={ label }
            >
              <div className="flex space-x-2 label  duration-75 text-nowrap">
                <Flaticon name="check" type="rr" size={ 18 } className={ clsx("text-green-600", value === data.client ? "visible" : "invisible") } />
                <div className="label">{ label }</div>
              </div>
            </FormifyComboboxOption>
          )) }
        </FormifyCombobox>
      </div>

    </Modality>
  );
};

export default InstallationModal;