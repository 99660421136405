import { useEffect, useState } from "react";
import req from "../../../../modules/Request";
import { useNotifyContext } from "../../../components/Notify";
import * as session from "../../../../modules/Stash/Stash";

const useTravelAvailabilities = ({ show }) => {
  const [availabilities, setAvailabilities] = useState(session.getStore("availabilities") || []);
  const { notify } = useNotifyContext();

  useEffect(() => {
    const fetchAvailabilities = async () => {
      const res = await req("select-values", { type: "DISP_TRASF" });

      if(res.success) {
        session.addStore("availabilities", res.data);
        setAvailabilities(res.data);
      } else {
        notify("Error", "Please contact the administrator", 'error');
      }
    };

    if(show) fetchAvailabilities();
  }, [ show ]);

  return availabilities;
};

export default useTravelAvailabilities;