import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useScheduler } from '..';
import { ContextureButtonStyle, ContexturePanelStyle, FormifyInputStyle } from '../../../../assets/styles';
import { ContextureButton, ContextureCategory, ContexturePanel } from '../../../../components/Contexture/Contexture';
import Flaticon from '../../../../components/Flaticon/Flaticon';
import { FormifyMultiAll, FormifyMultiOption, FormifyMultiSelect, FormifySingleSelect, FormifySingleSelectOption } from '../../../../components/Formify/Formify';
import req, { useReq } from '../../../../modules/Request';
import * as stash from "../../../../modules/Stash/Stash";
import { clsx } from '../../../../modules/Utilkit/Utilkit';
import { useNavigator } from '../../../components/Navigator';
import { useNotifyContext } from '../../../components/Notify';

const OPTIONS_X = 148;
const OPTIONS_Y = 100;
const OPTIONS_COUNT_OFFSET_Y = 23;
const OPTIONS_OFFSET_Y = 45;
const OPTIONS_OFFSET_X = 180;

const FilterPanelContext = createContext();
const useFilterPanel = () => useContext(FilterPanelContext);

const FilterPanelView = () => {
  const { showPer } = useScheduler();
  const {
    query,
    setQuery,
    range,
    setRange,
    showOptions,
    isVisible,
    handleSubmit,
    options,
    handleClosePanels,
    handleFilterPanelMouseEnter,
    handleFilterPanelMouseLeave,
    handleSelectFilter,
    handleCloseOptionPanel,
    activeOption
  } = useFilterPanel();

  return (
    <>
      { /* FILTER BY */ }
      <ContexturePanel
        className={ ContexturePanelStyle }
        simple={ true }
        show={ isVisible }
        onClose={ handleClosePanels }
        position={ { x: 180, y: 40 } }
      >
        <ContextureCategory label="Filter by Date">
          <div className='flex flex-col space-y-1 py-1'>
            <input
              className={ FormifyInputStyle }
              type="date"
              value={ range.from }
              onChange={ (e) => setRange((curr) => ({ ...curr, [ 'from' ]: e.target.value })) }
            />

            <input
              className={ FormifyInputStyle }
              type="date"
              value={ range.to }
              onChange={ (e) => setRange((curr) => ({ ...curr, [ 'to' ]: e.target.value })) }
            />
          </div>

        </ContextureCategory>

        <ContextureCategory label="Filter by">
          { options[ showPer ].map(({ label, id, selected, position }) => (
            <ContextureButton
              className={ ContextureButtonStyle }
              key={ id }
              onMouseEnter={ (e) => handleFilterPanelMouseEnter(e, id, selected, position) }
              onMouseLeave={ handleFilterPanelMouseLeave }
              onClick={ (e) => handleSelectFilter(e, id, selected, position) }
            >
              <span className='w-full text-start'>{ label }</span>
              <Flaticon name="angle-right" type="rr" size={ 11 } />
            </ContextureButton>
          )) }
        </ContextureCategory>

        { /* OPTIONS LIST */ }
        <ContexturePanel
          className={ clsx(ContexturePanelStyle, "max-h-[200px] overflow-y-auto") }
          simple={ true }
          outClose={ true }
          show={ showOptions.show }
          onClose={ handleCloseOptionPanel }
          position={ showOptions.position }
        >
          <ContextureCategory label={ activeOption?.label }>
            <input
              className={ FormifyInputStyle }
              type="text"
              placeholder="Search"
              value={ query }
              onInput={ (e) => setQuery(e.target.value) }
            />

            { activeOption?.type === 'multiple' ? (
              <FormifyMultiSelect
                selected={ activeOption?.selected ?? [] }
                onChange={ (value) => handleSubmit({ id: activeOption.id, value }) }
              >
                <FormifyMultiAll className="flex w-full items-center">
                  { ({ isSelectedAll, isSelectedHalf }) => {
                    return (
                      <>
                        { isSelectedAll ? (
                          <Flaticon name="checkbox" type="br" size={ 13 } className="w-4" />
                        ) : isSelectedHalf ? (
                          <Flaticon name="square-minus" type="br" size={ 13 } className="w-4" />
                        ) : (
                          <Flaticon name="square" type="br" size={ 13 } className="w-4" />
                        ) }
                        <span>All</span>
                      </>
                    );
                  } }
                </FormifyMultiAll>

                { activeOption?.options.map(({ value, label }) => (
                  <FormifyMultiOption
                    key={ value }
                    value={ value }
                    className={ clsx("flex w-full items-center", label.toUpperCase().includes(query.toUpperCase()) ? 'visible' : 'hidden') }
                  >
                    { ({ isSelected }) => {
                      return (
                        <>
                          { isSelected ? (
                            <Flaticon name="checkbox" type="br" size={ 13 } className="w-4" />
                          ) : (
                            <Flaticon name="square" type="br" size={ 13 } className="w-4" />
                          ) }
                          <span>{ label }</span>
                        </>
                      );
                    } }
                  </FormifyMultiOption>
                )) }
              </FormifyMultiSelect>
            ) : (
              <FormifySingleSelect
                selected={ activeOption?.selected }
                onChange={ (value) => handleSubmit({ id: activeOption.id, value }) }
              >
                { activeOption?.options.map(({ value, label }) => (
                  <FormifySingleSelectOption
                    key={ value }
                    className={ clsx("flex w-full items-center hover:", label.toUpperCase().includes(query.toUpperCase()) ? 'visible' : 'hidden') }
                    value={ value }
                  >
                    { activeOption?.selected === value ? (
                      <Flaticon name="check" type="br" size={ 13 } className="w-4" />
                    ) : (
                      <div className="w-4" />
                    ) }
                    <span>{ label }</span>
                  </FormifySingleSelectOption>
                )) }
              </FormifySingleSelect>
            ) }
          </ContextureCategory>
        </ContexturePanel>
      </ContexturePanel>
    </>
  );
};

const FilterPanel = () => {
  const { filters, setFilters, showPer } = useScheduler();
  const { addQuickElement } = useNavigator();

  const { notify } = useNotifyContext();

  const [ range, setRange ] = useState(filters.range);
  const [ query, setQuery ] = useState('');
  const [ isVisible, setIsVisible ] = useState(false);
  const [ showOptions, setShowOptions ] = useState({ show: false, position: { x: 0, y: 0 }, filterID: undefined });

  const [ offices ] = useReq({
    url: 'offices',
    handleError: () => notify('Error', 'Failed to fetch offices', 'error'),
    handleSelect: async () => await req('select-offices')
  });

  const [ travels ] = useReq({
    url: 'values',
    handleError: () => notify('Error', 'Failed to fetch travels', 'error'),
    handleSelect: async () => {
      const res = await req('select-values', { type: 'DISP_TRASF' });

      return res;
    }
  });

  const [ interventions ] = useReq({
    url: 'interventions',
    handleError: () => notify('Error', 'Failed to fetch interventions', 'error'),
    handleSelect: async () => {
      const res = await req('select-interventions');

      if (res.success) {
        res.data = res.data.map(item => ({ ...item, value: item.id, label: item.description }));
      }

      return res;
    }
  });

  const [ installations ] = useReq({
    url: 'installations',
    handleError: () => notify('Error', 'Failed to fetch installations', 'error'),
    handleSelect: async () => {
      const res = await req('select-installations');

      if (res.success) {
        res.data = res.data.map(item => ({ ...item, value: item.id, label: item.description }));
      }

      return res;
    }
  });

  const [ resources ] = useReq({
    url: 'resources',
    handleError: () => notify('Error', 'Failed to fetch resources', 'error'),
    handleSelect: async () => {
      const res = await req('select-resources');

      if (res.success) {
        res.data = res.data.map(({ id, fullname }) => ({ value: id, label: fullname }));
      }

      return res;
    }
  });

  const [ responsables ] = useReq({
    url: 'responsables',
    handleError: () => notify('Error', 'Failed to fetch responsables', 'error'),
    handleSelect: async () => {
      const res = await req('select-responsables');

      if (res.success) {
        res.data = res.data.map(({ username, fullname }) => ({ value: username, label: fullname }));
      }

      return res;
    }
  });

  const timoutMouseEnter = useRef(null);

  const options = {
    "resources": [
      { position: 0, type: 'multiple', label: "Office", id: 'offices', selected: filters.offices, options: offices },
      { position: 1, type: 'multiple', label: "Travel", id: 'travels', selected: filters.travels, options: travels },
      { position: 2, type: 'multiple', label: "Resource", id: 'resources', selected: filters.resources, options: resources },
      { position: 3, type: 'multiple', label: "Responsable", id: 'responsables', selected: filters.responsables, options: responsables },
      { position: 4, type: 'default', label: "Show Completed", id: 'showCompleted', selected: filters.showCompleted, options: [ { value: 1, label: 'Yes' }, { value: 0, label: 'No' } ] }
    ],
    "projects": [
      { position: 0, type: 'multiple', label: "Intervention", id: 'interventions', selected: filters.interventions, options: interventions },
      { position: 1, type: 'multiple', label: "Installation", id: 'installations', selected: filters.installations, options: installations },
      { position: 2, type: 'default', label: "Show Completed", id: 'showCompleted', selected: filters.showCompleted, options: [ { value: 1, label: 'Yes' }, { value: 0, label: 'No' } ] }
    ]
  };

  
  const activeOption = options[ showPer ].find(({ id }) => {
    
    return id === showOptions.filterID
  });

  const handleSubmit = ({ id, value }) => setFilters((curr) => stash.addStore('scheduler-filters', { ...curr, [ id ]: value }));

  const handleClosePanels = () => {
    if (filters.range.from !== range.from || filters.range.to !== range.to) handleSubmit({ id: 'range', value: { from: range.from, to: range.to } });

    setQuery('');

    clearTimeout(timoutMouseEnter.current);
    timoutMouseEnter.current = null;
    setShowOptions(curr => ({ ...curr, show: false }));
    setIsVisible(false);
  };

  const handleFilterPanelMouseEnter = (e, id, selected, position) => {
    if (timoutMouseEnter.current) {
      setShowOptions(curr => ({ ...curr, show: false }));

      clearTimeout(timoutMouseEnter.current);
      timoutMouseEnter.current = null;
    }

    timoutMouseEnter.current = setTimeout(() => {
      setQuery('');

      setShowOptions({
        show: true,
        position: { x: OPTIONS_X + OPTIONS_OFFSET_X, y: OPTIONS_Y + OPTIONS_OFFSET_Y + (OPTIONS_COUNT_OFFSET_Y * position) },
        filterID: id,
        value: selected
      });
    }, 500);
  };

  const handleFilterPanelMouseLeave = () => {
    if (timoutMouseEnter.current) {
      clearTimeout(timoutMouseEnter.current);
      timoutMouseEnter.current = null;
    }
  };

  const handleSelectFilter = (e, id, selected, position) => {
    if (timoutMouseEnter.current) {
      setShowOptions(curr => ({ ...curr, show: false }));

      clearTimeout(timoutMouseEnter.current);
      timoutMouseEnter.current = null;
    }

    setQuery('');

    setShowOptions({
      show: true,
      position: { x: OPTIONS_X + OPTIONS_OFFSET_X, y: OPTIONS_Y + OPTIONS_OFFSET_Y + (OPTIONS_COUNT_OFFSET_Y * position) },
      filterID: id,
      value: selected
    });
  };

  const handleCloseOptionPanel = () => {
    setShowOptions(curr => ({ ...curr, show: false }));
  };

  useEffect(() => addQuickElement({ position: 1, id: 'filter', name: "Filter", icon: { name: "filter", type: "rr" }, type: "button", onClick: () => setIsVisible(!isVisible) }), []);

  return (
    <FilterPanelContext.Provider value={ {
      showOptions,
      query,
      setQuery,
      range,
      setRange,
      isVisible,
      onClose: () => setIsVisible(false),
      activeOption,
      handleSubmit,
      options,
      handleClosePanels,
      handleFilterPanelMouseEnter,
      handleFilterPanelMouseLeave,
      handleSelectFilter,
      handleCloseOptionPanel
    } }>
      <FilterPanelView />
    </FilterPanelContext.Provider>
  );
};


export default FilterPanel;
