import { useEffect, useState } from "react";
import req from "../../../../modules/Request";
import { useNotifyContext } from "../../../components/Notify";
import * as session from "../../../../modules/Stash/Stash";

const useOffices = ({ show }) => {
  const [ offices, setOffices ] = useState(session.getStore("offices") || []);
  const { notify } = useNotifyContext();

  useEffect(() => {
    const fetchOffices = async () => {
      const res = await req("select-offices");

      if (res.success) {
        session.addStore("offices", res.data);
        setOffices(res.data);
      } else {
        notify("Error", "Please contact the administrator", 'error');
      }
    };

    if (show) fetchOffices();
  }, [ show ]);

  return offices;
};

export default useOffices;