import { useEffect, useState } from "react";
import req from "../../../../modules/Request";
import { useNotifyContext } from "../../../components/Notify";
import * as session from "../../../../modules/Stash/Stash";

const useInstallations = ({ show }) => {
  const [ installations, setInstallations ] = useState(session.getStore("installations") || []);
  const { notify } = useNotifyContext();

  useEffect(() => {
    const fetchInstallations = async () => {
      const res = await req("select-installations");

      if (res.success) {
        const newDate = res.data.map(item => ({ value: item.id, label: item.description, hashTags: [ item.client.split(' ').map(v => v && v.length > 0 ? (v[0].toUpperCase() + v.substring(1).toLowerCase()) : "").join('') ] }));
        session.addStore("installations", newDate);
        setInstallations(newDate);
      } else {
        notify("Error", "Please contact the administrator", 'error');
      }
    };

    if (show) fetchInstallations();
  }, [ show ]);

  return installations;
};

export default useInstallations;