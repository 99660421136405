import { Transition } from "@headlessui/react";
import { useEffect, useState } from "react";
import Modality from "../../../components/Modality/Modality";
import req from "../../../modules/Request";
import * as session from "../../../modules/Stash/Stash";
import { clsx } from "../../../modules/Utilkit/Utilkit";
import { useAuth } from "../../components/Auth";
import { useNavigator } from "../../components/Navigator";
import { useNotifyContext } from "../../components/Notify";
import ActivityModal from "./components/ActivityModal";
import ClientModal from "./components/ClientModal";
import InstallationModal from "./components/InstallationModal";
import InterventionModal from "./components/InterventionModal";
import JobModal from "./components/JobModal";
import ProjectModal from "./components/ProjectModal";
import ResourceCalendarModal from "./components/ResourceCalendarModal";
import ResourceModal from "./components/ResourceModal";
import SkillMatrixModal from "./components/SkillMatrixModal";
import Table from "./components/Table";
import useTableData from "./hooks/useTableData";

/**
 * params: {
 *  "selected-topic": "projects", // "resources" | "resources-calendar" | "clients" |
 *                                // "jobs" | "installations" | "activities" | "interventions"
 *  "form-modal": {
 *    show: "resource-form", // "resource-calendar-form" | "clients-form" | "jobs-form" | "activities-form" |
 *                           // "interventions-form" | "installations-form" | "projects-form" | "skillmatrix-form"
 *    id: 1 // number
 *  }
 * }
 */

const DataManagement = () => {
  const { params, setQuickAccessPanel, go } = useNavigator();
  const { userData: { permissions } } = useAuth();
  const { notify } = useNotifyContext();

  const [ query, setQuery ] = useState("");

  const topics = [
    {
      name: "Projects",
      id: "projects",
      columns: [ "ID", "Name", "Start Date", "End Date" ],
      columnsIDs: [ "id", "description", "startDate", "endDate" ],
      quickAccess: [
        { name: "Project Search", type: "search", onChange: (value) => setQuery(value || "") },
        { name: "Add Project", icon: { name: "plus", type: "rr" }, type: "button", onClick: () => handleShowCreateModal("projects-form") }
      ],
      context: [
        [
          { name: "Edit Project", icon: { name: "edit", type: "rr" }, onClick: ({ row: { id } }) => handleShowEditModal("projects-form", id) },
          {
            name: "Delete Project", icon: { name: "trash", type: "rr" }, onClick: async ({ row }) => {
              setShowDeleteConfirmModal({
                show: true,
                onConfirm: async () => {
                  const res = await req("delete-project", { id: row.id });
                  if (res.success) {
                    setTableData(curr => {
                      const newData = curr.data.filter(item => item.id !== row.id);
                      return { ...curr, data: newData };
                    });
                    notify("Success", "Project has been deleted", "success");
                  } else {
                    notify("Error", "Please contact the administrator", 'error');
                  }
                }
              });
            }
          },
          {
            name: "Dublicate Project", icon: { name: "clipboard", type: "rr" }, onClick: async ({ row: { id } }) => {
              const fetchData = async () => {
                const res = await req("select-projects-raw", { id });

                if (res.success) {
                  const res2 = await req("select-project-resources", { id });

                  if (res2.success) {
                    return { ...res.data[ 0 ], id: undefined, resourcesA: [], resourcesB: [], temporaryA: [], temporaryB: [] };
                  } else {
                    notify("Error", "Please contact the administrator", 'error');
                  }
                } else {
                  notify("Error", "Please contact the administrator", 'error');
                }
              };
              const baseProject = await fetchData();
              go('data-management', { "selected-topic": "projects", "projects-form": { ...baseProject }, "form-modal": { show: 'projects-form' } });
            }
          }
        ]
      ]
    },
    {
      name: "Resources",
      id: "resources",
      columns: [ "ID", "Surname Name", "Office", "Responsible", "Travel Availability", "Active" ],
      columnsIDs: [ "id", "fullname", "office", "responsable", "travelAvailability", "active" ],
      quickAccess: [
        {
          name: "Add Resource",
          icon: { name: "plus", type: "rr" },
          type: "button",
          onClick: () => handleShowCreateModal("resource-form")
        }
      ],
      context: [
        [
          {
            name: "Edit Resource", icon: { name: "edit", type: "rr" }, onClick: ({ row: { id } }) => {
              handleShowEditModal("resource-form", id);
            }
          },
          ...(permissions?.includes("SSP_AM_RESMAN") ? [ {
            name: "Edit Skill Matrix", icon: { name: "list", type: "rr" }, onClick: ({ row: { id, office } }) => {
              handleShowEditModal("skillmatrix-form", id, { office });
            }
          } ] : []),
          {
            name: "Delete Resource", icon: { name: "trash", type: "rr" }, onClick: async ({ row }) => {
              setShowDeleteConfirmModal({
                show: true,
                onConfirm: async () => {
                  const res = await req("delete-resource", { id: row.id });
                  if (res.success) {
                    setTableData(curr => {
                      const newData = curr.data.filter(item => item.id !== row.id);
                      return { ...curr, data: newData };
                    });
                    notify("Success", "Resource has been deleted", "success");
                  } else {
                    if (res.error === "access-denied") {
                      notify("Error", "You do not have the necessary permissions", 'error');
                    } else if (res.error === "in-use") {
                      notify("Warning", "Resource is in use", 'error');
                    } else if (res.error === "resource-has-calendar") {
                      notify("Warning", "Resource has calendar", 'error');
                    } else {
                      notify("Error", "Please contact the administrator", 'error');
                    }
                  }
                }
              });
            }
          }
        ]
      ]
    },
    {
      name: "Resources Calendar",
      id: "resources-calendar",
      columns: [ "ID", "Resource", "Office", "Start Date", "End Date", "Reason" ],
      columnsIDs: [ "id", "fullname", "office", "startDate", "endDate", "reason" ],
      quickAccess: [
        {
          name: "Add Resource Calendar",
          icon: { name: "plus", type: "rr" },
          type: "button",
          onClick: () => handleShowCreateModal("resource-calendar-form")
        }
      ],
      context: [
        [
          {
            name: "Edit Resource Calendar", icon: { name: "edit", type: "rr" }, onClick: ({ row: { id } }) => {
              handleShowEditModal("resource-calendar-form", id);
            }
          },
          {
            name: "Delete Resource Calendar", icon: { name: "trash", type: "rr" }, onClick: async ({ row }) => {
              setShowDeleteConfirmModal({
                show: true,
                onConfirm: async () => {
                  const res = await req("delete-resource-calendar", { id: row.id });
                  if (res.success) {
                    setTableData(curr => {
                      const newData = curr.data.filter(item => item.id !== row.id);
                      return { ...curr, data: newData };
                    });
                    notify("Success", "Resource Calendar has been deleted", "success");
                  } else {
                    if (res.error === "access-denied") {
                      notify("Error", "You do not have the necessary permissions", 'error');
                    } else {
                      notify("Error", "Please contact the administrator", 'error');
                    }
                  }
                }
              });
            }
          }
        ]
      ]
    },
    {
      name: "Clients",
      id: "clients",
      columns: [ "ID", "Name", "Note" ],
      columnsIDs: [ "id", "description", "notes" ],
      quickAccess: [
        { name: "Add Client", icon: { name: "plus", type: "rr" }, type: "button", onClick: () => handleShowCreateModal("clients-form") }
      ],
      context: [
        [
          { name: "Edit Client", icon: { name: "edit", type: "rr" }, onClick: ({ row: { id } }) => handleShowEditModal("clients-form", id) },
          {
            name: "Delete Client", icon: { name: "trash", type: "rr" }, onClick: async ({ row }) => {
              setShowDeleteConfirmModal({
                show: true,
                onConfirm: async () => {
                  const res = await req("delete-client", { id: row.id });
                  if (res.success) {
                    setTableData(curr => {
                      const newData = curr.data.filter(item => item.id !== row.id);
                      return { ...curr, data: newData };
                    });
                    notify("Success", "Client has been deleted", "success");
                  } else {
                    if (res.error === "access-denied") {
                      notify("Error", "You do not have the necessary permissions", 'error');
                    } else if (res.error === "in-use") {
                      notify("Warning", "Client is in use", 'warn');
                    } else {
                      notify("Error", "Please contact the administrator", 'error');
                    }
                  }
                }
              });
            }
          }
        ]
      ]
    },
    {
      name: "Job Nr",
      id: "jobs",
      columns: [ "ID", "Name" ],
      columnsIDs: [ "id", "description" ],
      quickAccess: [
        { name: "Add Job", icon: { name: "plus", type: "rr" }, type: "button", onClick: () => handleShowCreateModal("jobs-form") }
      ],
      context: [
        [
          { name: "Edit Job", icon: { name: "edit", type: "rr" }, onClick: ({ row: { id } }) => handleShowEditModal("jobs-form", id) },
          {
            name: "Delete Job", icon: { name: "trash", type: "rr" }, onClick: async ({ row }) => {
              setShowDeleteConfirmModal({
                show: true,
                onConfirm: async () => {
                  const res = await req("delete-job", { id: row.id });
                  if (res.success) {
                    setTableData(curr => {
                      const newData = curr.data.filter(item => item.id !== row.id);
                      return { ...curr, data: newData };
                    });
                    notify("Success", "Job has been deleted", "success");
                  } else {
                    if (res.error === "access-denied") {
                      notify("Error", "You do not have the necessary permissions", 'error');
                    } else if (res.error === "in-use") {
                      notify("Warning", "Job is in use", 'warn');
                    } else {
                      notify("Error", "Please contact the administrator", 'error');
                    }
                  }
                }
              });
            }
          }
        ]
      ]
    },
    {
      name: "Installations",
      id: "installations",
      columns: [ "ID", "Name", "City", "Nation", "Client" ],
      columnsIDs: [ "id", "description", "city", "nation", "client" ],
      quickAccess: [
        { name: "Add Installation", icon: { name: "plus", type: "rr" }, type: "button", onClick: () => handleShowCreateModal("installations-form") }
      ],
      context: [
        [
          { name: "Edit Installation", icon: { name: "edit", type: "rr" }, onClick: ({ row: { id } }) => handleShowEditModal("installations-form", id) },
          {
            name: "Delete Installation", icon: { name: "trash", type: "rr" }, onClick: async ({ row }) => {
              setShowDeleteConfirmModal({
                show: true,
                onConfirm: async () => {
                  const res = await req("delete-installation", { id: row.id });
                  if (res.success) {
                    setTableData(curr => {
                      const newData = curr.data.filter(item => item.id !== row.id);
                      return { ...curr, data: newData };
                    });
                    notify("Success", "Installation has been deleted", "success");
                  } else {
                    if (res.error === "access-denied") {
                      notify("Error", "You do not have the necessary permissions", 'error');
                    } else if (res.error === 'in-use') {
                      notify("Warning", "Installation is in use", 'warn');
                    } else {
                      notify("Error", "Please contact the administrator", 'error');
                    }
                  }
                }
              });
            }
          }
        ]
      ]
    },
    {
      name: "Activity Types",
      id: "activities",
      columns: [ "ID", "Name", "Sequence", "active" ],
      columnsIDs: [ "id", "description", "sequence", "status" ],
      quickAccess: [
        { name: "Add Activity", icon: { name: "plus", type: "rr" }, type: "button", onClick: () => handleShowCreateModal("activities-form") }
      ],
      context: [
        [
          { name: "Edit Activity", icon: { name: "edit", type: "rr" }, onClick: ({ row: { id } }) => handleShowEditModal("activities-form", id) },
          {
            name: "Delete Activity", icon: { name: "trash", type: "rr" }, onClick: async ({ row }) => {
              setShowDeleteConfirmModal({
                show: true,
                onConfirm: async () => {
                  const res = await req("delete-activity", { id: row.id });
                  if (res.success) {
                    setTableData(curr => {
                      const newData = curr.data.filter(item => item.id !== row.id);
                      return { ...curr, data: newData };
                    });
                    notify("Success", "Activity has been deleted", "success");
                  } else {
                    if (res.error === "access-denied") {
                      notify("Error", "You do not have the necessary permissions", 'error');
                    } else if (res.error === 'in-use') {
                      notify("Warning", "Activity is in use", 'warn');
                    } else {
                      notify("Error", "Please contact the administrator", 'error');
                    }
                  }
                }
              });
            }
          }
        ]
      ]
    },
    {
      name: "Intervention Types",
      id: "interventions",
      columns: [ "ID", "Name", "Sequence", "Colore" ],
      columnsIDs: [ "id", "description", "sequence", "color" ],
      quickAccess: [
        { name: "Add Intervention", icon: { name: "plus", type: "rr" }, type: "button", onClick: () => handleShowCreateModal("interventions-form") }
      ],
      context: [
        [
          { name: "Edit Intervention", icon: { name: "edit", type: "rr" }, onClick: ({ row: { id } }) => handleShowEditModal("interventions-form", id) },
          {
            name: "Delete Intervention", icon: { name: "trash", type: "rr" }, onClick: async ({ row }) => {
              setShowDeleteConfirmModal({
                show: true,
                onConfirm: async () => {
                  const res = await req("delete-intervention", { id: row.id });
                  if (res.success) {
                    setTableData(curr => {
                      const newData = curr.data.filter(item => item.id !== row.id);
                      return { ...curr, data: newData };
                    });
                    notify("Success", "Intervention has been deleted", "success");
                  } else {
                    if (res.error === "access-denied") {
                      notify("Error", "You do not have the necessary permissions", 'error');
                    } else if (res.error === 'in-use') {
                      notify("Warning", "Intervention is in use", 'warn');
                    } else {
                      notify("Error", "Please contact the administrator", 'error');
                    }
                  }
                }
              });
            }
          }
        ]
      ]
    }
  ];

  const [ formModal, setFormModal ] = useState({ show: false, data: undefined, id: undefined });
  const [ selectedTopic, setSelectedTopic ] = useState(params[ "selected-topic" ] ? (
    topics.find(topic => topic.id === params[ "selected-topic" ])
  ) : (
    false
  ));
  const { tableData, setTableData, showTable } = useTableData({ selectedTopic, setQuickAccessPanel });
  const [ showDeleteConfirmModal, setShowDeleteConfirmModal ] = useState({ show: false, onConfirm: undefined });

  const handleSelectTopic = (id) => {
    session.addParam("selected-topic", id);
    setSelectedTopic(topics.find(topic => topic.id === id));
    setQuery("");
  };

  const handleShowCreateModal = (modalType) => {
    session.addParam("form-modal", { show: modalType, data: undefined, id: undefined });
    setFormModal({ show: modalType, data: undefined, id: undefined });
  };

  const handleShowEditModal = (modalType, id, data) => {
    session.addParam("form-modal", { show: modalType, id, data });
    setFormModal({ show: modalType, id, data });
  };

  const handleHideModal = () => {
    session.delParam("form-modal");
    setFormModal({ show: false, id: undefined, data: undefined });
  };

  useEffect(() => {
    if (params[ "selected-topic" ]) {
      const topic = topics.find(topic => topic.id === params[ "selected-topic" ]);
      if (!topic) {
        session.delParam("selected-topic");
        return;
      }

      setSelectedTopic(topics.find(topic => topic.id === params[ "selected-topic" ]));
    }

    if (params[ "form-modal" ]) {
      setFormModal(params[ "form-modal" ]);
    }
  }, [ params ]);

  return (
    <div className="flex w-full h-full">
      <Modality
        show={ showDeleteConfirmModal.show }
        label="Warning!"
        icon={ { name: "triangle-warning", type: "br", className: "text-red-600" } }
        buttons={ [
          { name: "Cancel", onClick: () => setShowDeleteConfirmModal({ show: false, onConfirm: undefined }) },
          {
            name: "Delete", styleSet: 'error', onClick: () => {
              showDeleteConfirmModal.onConfirm();
              setShowDeleteConfirmModal({ show: false, onConfirm: undefined });
            }
          }
        ] }
        onClose={ () => setShowDeleteConfirmModal({ show: false, onConfirm: undefined }) }
      >
        Are you sure you want to delete this row?
      </Modality>

      <div className="w-48 min-w-48 h-full bg-[#0050a3] p-2 space-y-1 shadow-lg">
        <div className="flex justify-center border-b border-white">
          <h1 className="text-white text-lg">Data Management</h1>
        </div>

        { topics.map(({ name, id }, i) => {
          return (
            <button
              key={ 'topic-' + i }
              className={ clsx(
                "w-full p-1 flex justify-start items-center text-white hover:bg-blue-600 rounded-lg text-nowrap",
                selectedTopic.id === id && "bg-blue-600/70"
              ) }
              onClick={ () => handleSelectTopic(id) }
            >
              { name }
            </button>
          );
        }) }
      </div>

      <ResourceModal
        show={ formModal.show === "resource-form" }
        setTableData={ setTableData }
        id={ formModal.id }
        onClose={ handleHideModal }
      />

      <ResourceCalendarModal
        show={ formModal.show === "resource-calendar-form" }
        onClose={ handleHideModal }
        id={ formModal.id }
        setTableData={ setTableData }
      />

      <ClientModal
        show={ formModal.show === "clients-form" }
        onClose={ handleHideModal }
        id={ formModal.id }
        setTableData={ setTableData }
      />

      <JobModal
        show={ formModal.show === "jobs-form" }
        onClose={ handleHideModal }
        id={ formModal.id }
        setTableData={ setTableData }
      />

      <ActivityModal
        show={ formModal.show === "activities-form" }
        onClose={ handleHideModal }
        id={ formModal.id }
        setTableData={ setTableData }
      />

      <InterventionModal
        show={ formModal.show === "interventions-form" }
        onClose={ handleHideModal }
        id={ formModal.id }
        setTableData={ setTableData }
      />

      <InstallationModal
        show={ formModal.show === "installations-form" }
        onClose={ handleHideModal }
        id={ formModal.id }
        setTableData={ setTableData }
      />

      <SkillMatrixModal
        show={ formModal.show === "skillmatrix-form" }
        onClose={ handleHideModal }
        id={ formModal.id }
        data={ formModal.data }
      />

      <ProjectModal
        show={ formModal.show === "projects-form" }
        onClose={ handleHideModal }
        id={ formModal.id }
        setTableData={ setTableData }
      />

      <div className="w-full h-full overflow-y-auto overflow-x-hidden">
        <Transition
          show={ showTable }
          as="div"
          className="w-full h-full overflow-x-auto"
          enter="transition-opacity duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          { tableData ? (
            tableData.data.length > 0 ? (
              <Table
                data={ query != "" ? (
                  tableData.data?.filter(item => {
                    const columns = selectedTopic.columnsIDs;
                    const queryLower = query.toLowerCase();
                    const itemValues = columns.map(column => item[ column ]);

                    return itemValues.some(value => {
                      if (typeof value === "string") {
                        return value.toLowerCase().includes(queryLower);
                      } else {
                        return false;
                      }
                    }, []);

                  })
                ) : (
                  tableData.data
                ) }
                columns={ tableData.columns }
                columnsIDs={ tableData.columnsIDs }
                context={ tableData.context }
              />
            ) : (
              <div className="flex justify-center items-center h-full w-full">
                <h1 className="text-xl">No data found</h1>
              </div>
            )
          ) : (
            <div className="flex justify-center items-center h-full w-full">
              <h1 className="text-xl">Select a topic to view data</h1>
            </div>
          ) }
        </Transition>
      </div>
    </div>
  );
};

export default DataManagement;