import { useEffect, useRef } from "react";
import { useWeGanttConsumer } from "..";

const Table = ({ tasks }) => {
  const { cellHeight, fontSize, TableColumns, heightOfHeader, TableRow } = useWeGanttConsumer();

  const hasResize = useRef(false);
  const tableRef = useRef(null);
  const initialWidth = useRef(0);

  useEffect(() => {
    initialWidth.current = tableRef.current.scrollWidth;

    const handleMouseMove = (e) => {
      if (hasResize.current) {
        e.preventDefault();
        if (e.clientX - initialWidth.current < 0) {
          tableRef.current.style.width = `${e.clientX}px`;
        } else {
          tableRef.current.style.width = `${initialWidth.current}px`;
        }
      }
    };

    const handleMouseUp = (e) => {
      e.stopPropagation();
      hasResize.current = false;
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [ cellHeight, tasks ]);

  return (
    <div className="flex h-min">
      <div ref={ tableRef } className="flex w-max">
        <table className="h-full table-fixed border-spacing-0 text-nowrap">
          <thead>
            <tr style={ { height: heightOfHeader } } className="sticky top-0 z-[15] shadow-sm">
              { typeof TableColumns === 'function' ? (
                TableColumns().map((item, index) => (
                  <th key={ index } className="p-0 [&:not(:last-child)>div]:border-r">
                    <div className="flex px-1 justify-center items-center h-full bg-white border-b border-gray-200" style={ { fontSize } }>
                      { item }
                    </div>
                  </th>
                ))
              ) : (
                TableColumns.map((item, index) => (
                  <th key={ index } className="p-0">
                    <div className="flex px-1 justify-center items-center h-full bg-white border-r border-b border-gray-200" style={ { fontSize } }>
                      { item }
                    </div>
                  </th>
                ))
              ) }
            </tr>
          </thead>
          <tbody>
            { tasks.map((task) => (
              <TableRow key={ task.id } { ...task } />
            )) }
          </tbody>
        </table>
      </div>
      <div
        className="flex items-center justify-center bg-gray-100 hover:bg-gray-200 cursor-e-resize z-[30]"
        style={ { width: 10, height: (tasks.length * cellHeight) + heightOfHeader } }
        onMouseDown={ (e) => {
          e.preventDefault();
          hasResize.current = true;
        } }
      >
        <i className="fi fi-br-menu-dots-vertical text-xs" />
      </div>
    </div>
  );
};

export default Table;