

export const FormifyInputStyle = "border border-gray-300 rounded-md px-1.5 py-0.5 focus:ring-0 focus:border-blue-600 invalid:border-red-600 disabled:text-gray-500 disabled:bg-gray-200 font-medium";

export const FormifyComboboxStyle = {
  ComboboxInput: FormifyInputStyle + " w-full",
  ComboboxInputArrowButton: "group absolute inset-y-0 right-0 px-3.5",
  ComboboxInputArrowIcon: "text-gray-500 group-data-[hover]:fill-white",
  ComboboxOptions: "z-50 border bg-white rounded-md divide-y empty:invisible shadow-[0_0_4px_0_#00000020]",
}

export const FormifySelectStyle = {
  Select: FormifyInputStyle + " flex items-center justify-start space-x-1 w-full",
  Options: "z-50 border bg-white rounded-md divide-y empty:invisible shadow-[0_0_4px_0_#00000020]"
}

export const ContexturePanelStyle = "w-full border focus:outline-none border-gray-300 bg-white rounded-lg shadow-lg flex flex-col p-1 min-w-36";

export const ContextureButtonStyle = "flex justify-start rounded-md items-center px-3 py-0.5 hover:bg-blue-600 space-x-2 text-sm text-nowrap hover:text-white";