import { useEffect, useState } from "react";
import { useNavigator } from "../../../components/Navigator";
import useResources from "../hooks/useResources";
import * as session from "../../../../modules/Stash/Stash";
import { clsx } from "../../../../modules/Utilkit/Utilkit";
import req from "../../../../modules/Request";
import { useNotifyContext } from "../../../components/Notify";
import useReasons from "../hooks/useReasons";
import { useAuth } from "../../../components/Auth";

import { FormifyCombobox, FormifyComboboxOption } from "../../../../components/Formify/Formify";
import Modality from "../../../../components/Modality/Modality";
import Flaticon from "../../../../components/Flaticon/Flaticon";
import { FormifyComboboxStyle, FormifyInputStyle } from "../../../../assets/styles";


const ResourceCalendarModal = ({ show, onClose, setTableData, id }) => {
  const { params } = useNavigator();
  const { notify } = useNotifyContext();
  const { userData: { permissions } } = useAuth();
  const defaultData = { resourceID: "", startDate: undefined, endDate: undefined, reason: "" };

  const [ isLoading, setIsLoading ] = useState(false);
  const [ data, setData ] = useState(params[ "resources-calendar-form" ] || defaultData);
  const hasAccess = permissions.includes("SSP_AM_DATAMAN");
  const submitButtonLabel = hasAccess ? (isLoading ? "Loading..." : id ? "Edit" : "Add") : "No Access";

  const buttons = [
    { name: "Cancel", disabled: isLoading, onClick: () => handleClose() },
    { name: submitButtonLabel, type: "submit", styleSet: "success", disabled: isLoading || !hasAccess }
  ];

  const resources = useResources({ show });
  const reasons = useReasons({ show });

  const handleClose = () => {
    if (isLoading) return;
    setData(defaultData);
    session.delParam("resources-calendar-form");
    onClose();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (id) {
      const res = await req("update-resource-calendar", { ...data });

      if (res.success) {
        setTableData(curr => ({ ...curr, data: curr.data.map(item => item.id === res.data.id ? res.data : item) }));
        notify("Success", "Resource Calendar has been updated", "success");
        handleClose();
      } else {
        if (res.error === 'access-denied') {
          notify("Error", "You do not have the necessary permissions", 'error');
        } else {
          notify("Error", "Please contact the administrator", 'error');
        }
      }
    } else {
      const res = await req("insert-resource-calendar", { ...data });

      if (res.success) {
        setTableData(curr => curr.data ? { ...curr, data: [ ...curr.data, res.data ] } : { ...curr, data: [ res.data ] });
        notify("Success", "Resource Calendar has been added", "success");
        handleClose();
      } else {
        if (res.error === 'access-denied') {
          notify("Error", "You do not have the necessary permissions", 'error');
        } else {
          notify("Error", "Please contact the administrator", 'error');
        }
      }
    }

    setIsLoading(false);
  };

  const handleDataChange = (key, value) => {
    setData(curr => {
      const newData = { ...curr, [ key ]: value };
      session.addParam("resources-calendar-form", newData);
      return newData;
    });
  };

  useEffect(() => {
    const fetchResourceCalendar = async () => {
      setIsLoading(true);

      const res = await req("select-resource-calendar-raw", { id: id });

      if (res.success) {
        setData(res.data[ 0 ]);
      } else {
        notify("Error", "Please contact the administrator", 'error');
        handleClose();
      }

      setIsLoading(false);
    };

    if (id && show) fetchResourceCalendar();
  }, [ id ]);

  return (
    <Modality show={ show } onClose={ handleClose } onSubmit={ handleSubmit } buttons={ buttons } width="320px">
      { /* TITLE */ }
      <div className="text-xl">
        New Resource Calendar
      </div>

      { /* RESOURCE */ }
      <div className="flex flex-col w-full">
        <label htmlFor="resource">Resource</label>
        <FormifyCombobox
          className={ FormifyComboboxStyle }
          required={ true }
          disabled={ isLoading || !hasAccess }
          value={ data.resourceID }
          onChange={ (val) => handleDataChange("resourceID", val) }
        >
          { resources.map(({ value, label }) => (
            <FormifyComboboxOption
              key={ value }
              value={ value }
              label={ label }
              className={ clsx(
                "w-full h-full flex p-1 space-x-2 hover:space-x-0 items-center overflow-hidden cursor-pointer px-1 py-1",
                value === data.resourceID ? "bg-green-200" : "hover:bg-gray-200 data-[focus]:bg-gray-200"
              ) }
            >
              <div className="flex-1 flex">
                <div className="rounded-full bg-green-500 p-0.5 w-6 h-6 mr-2">
                  <div className="bg-white rounded-full flex justify-center items-center text-center font-medium text-[11px] w-full h-full">
                    <span>{ (label?.[ 0 ] ?? 'U') + (label?.split(' ')[ 1 ]?.[ 0 ] ?? '') }</span>
                  </div>
                </div>
                <span>
                  { label }
                </span>
              </div>
              <Flaticon name="check" type="rr" size={ 18 } className={ clsx("text-green-800", value === data.resourceID ? "visible" : "invisible") } />
            </FormifyComboboxOption>
          )) }

        </FormifyCombobox>
      </div>

      { /* REASON */ }
      <div className="flex flex-col w-full">
        <label htmlFor="reason">Reason</label>
        <FormifyCombobox
          className={ FormifyComboboxStyle }
          required={ true }
          disabled={ isLoading || !hasAccess }
          value={ data.reason }
          onChange={ (val) => handleDataChange("reason", val) }
        >
          { reasons.map(({ value, label }) => (
            <FormifyComboboxOption
              key={ value }
              value={ value }
              label={ label }
              className={ clsx(
                "w-full h-full flex p-1 space-x-2 hover:space-x-0 items-center overflow-hidden cursor-pointer",
                value === data.reason ? "bg-green-200" : "hover:bg-gray-200 data-[focus]:bg-gray-200"
              ) }
            >
              <div className="flex space-x-2 label  duration-75 text-nowrap">
                <Flaticon name="check" type="rr" size={ 18 } className={ clsx("text-green-800", value === data.reason ? "visible" : "invisible") } />
                <span className="label">{ label }</span>
              </div>
            </FormifyComboboxOption>
          )) }
        </FormifyCombobox>
      </div>

      { /* START DATE */ }
      <div className="flex flex-col w-full">
        <label htmlFor="startDate">Start Date</label>
        <input
          className={ FormifyInputStyle }
          required={ true }
          disabled={ isLoading || !hasAccess }
          type="date"
          max={ data.endDate }
          value={ data.startDate }
          onInput={ (e) => handleDataChange("startDate", e.target.value) }
        />
      </div>

      { /* END DATE */ }
      <div className="flex flex-col w-full">
        <label htmlFor="endDate">End Date</label>
        <input
          className={ FormifyInputStyle }
          required={ true }
          disabled={ isLoading || !hasAccess }
          type="date"
          min={ data.startDate }
          value={ data.endDate }
          onInput={ (e) => handleDataChange("endDate", e.target.value) }
        />
      </div>

    </Modality>
  );
};

export default ResourceCalendarModal;