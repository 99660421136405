import { useEffect, useState } from "react";
import { useNavigator } from "../../../components/Navigator";
import * as session from "../../../../modules/Stash/Stash";
import req from "../../../../modules/Request";
import { useNotifyContext } from "../../../components/Notify";

import Modality from "../../../../components/Modality/Modality";
import { FormifyInputStyle } from "../../../../assets/styles";


const InterventionModal = ({ show, onClose, setTableData, id }) => {
  const { params } = useNavigator();
  const { notify } = useNotifyContext();
  const defaultData = { description: "", sequence: 0, color: "#D0D0D0" };

  const [ isLoading, setIsLoading ] = useState(false);
  const [ data, setData ] = useState(params[ "interventions-form" ] ?? defaultData);

  const buttons = [
    { name: "Cancel", disabled: isLoading, onClick: () => handleClose() },
    { name: isLoading ? "Loading..." : id ? "Edit" : "Add", type: "submit", styleSet: "success", disabled: isLoading }
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (id) {
      const res = await req("update-intervention", { ...data });

      if (res.success) {
        setTableData(curr => ({ ...curr, data: curr.data.map(item => item.id === res.data.id ? res.data : item) }));
        notify("Success", "Intervention has been updated", "success");
        handleClose();
      } else {
        notify("Error", "Please contact the administrator", 'error');
      }
    } else {
      const res = await req("insert-intervention", { ...data });

      if (res.success) {
        setTableData(curr => curr.data ? { ...curr, data: [ ...curr.data, res.data ] } : { ...curr, data: [ res.data ] });
        notify("Success", "Intervention has been added", "success");
        handleClose();
      } else {
        notify("Error", "Please contact the administrator", 'error');
      }
    }

    setIsLoading(false);
  };

  const handleClose = () => {
    if (isLoading) return;
    setData(defaultData);
    session.delParam("interventions-form");
    onClose();
  };

  const handleDataChange = (key, value) => {
    setData(curr => {
      const newData = { ...curr, [ key ]: value };
      session.addParam("interventions-form", newData);
      return newData;
    });
  };

  useEffect(() => {
    const fetchJobs = async () => {
      setIsLoading(true);

      const res = await req("select-interventions", { id: id });

      if (res.success) {
        setData(res.data[ 0 ]);
      } else {
        notify("Error", "Please contact the administrator", 'error');
        handleClose();
      }

      setIsLoading(false);
    };

    if (id && show) fetchJobs();
  }, [ id ]);

  return (
    <Modality show={ show } onClose={ handleClose } onSubmit={ handleSubmit } width="320px" buttons={ buttons }>

      { /* TITLE */ }
      <div className="text-xl">New Intervention</div>

      { /* DESCRIPTION */ }
      <div className="flex flex-col w-full">
        <label htmlFor="name">Name</label>
        <input
          className={ FormifyInputStyle }
          name="name"
          required={ true }
          disabled={ isLoading }
          value={ data.description }
          onInput={ (e) => handleDataChange("description", e.target.value) }
        />
      </div>

      { /* SEQUENCE */ }
      <div className="flex flex-col w-full">
        <label htmlFor="sequence">Sequence</label>
        <input
          className={ FormifyInputStyle }
          required={ true }
          type="number"
          step={ 1 }
          min={ 0 }
          disabled={ isLoading }
          value={ data.sequence }
          onInput={ (e) => handleDataChange("sequence", e.target.value) }
        />
      </div>

      { /* COLOR */ }
      <div className="flex flex-col w-full">
        <label htmlFor="color">Color</label>
        <div className="flex justify-between">
          <h6>{ data.color }</h6>
          <input
            required={ true }
            type="color"
            disabled={ isLoading }
            value={ data.color }
            onChange={ (e) => handleDataChange("color", e.target.value) }
          />
        </div>
      </div>

    </Modality>
  );
};

export default InterventionModal;