import { useEffect, useState } from "react";
import req from "../../../../modules/Request";
import * as session from "../../../../modules/Stash/Stash";
import useOffices from "../hooks/useOffices";
import { useNotifyContext } from "../../../components/Notify";
import useTravelAvailabilities from "../hooks/useTravelAvailabilities";
import { useNavigator } from "../../../components/Navigator";
import useResponsables from "../hooks/useResponsables";
import { useAuth } from "../../../components/Auth";
import { FormifyCombobox, FormifyComboboxOption } from "../../../../components/Formify/Formify";
import Modality from "../../../../components/Modality/Modality";
import { FormifyComboboxStyle, FormifyInputStyle } from "../../../../assets/styles";
import { clsx } from "../../../../modules/Utilkit/Utilkit";
import Flaticon from "../../../../components/Flaticon/Flaticon";

const ResourceModal = ({ show, onClose, setTableData, id }) => {
  const { params } = useNavigator();
  const { notify } = useNotifyContext();
  const { userData: { permissions } } = useAuth();
  const defaultData = { fullname: "", office: "", responsable: "", travelAvailability: "" };

  const [ data, setData ] = useState(params[ "resources-form" ] || defaultData);
  const [ isLoading, setIsLoading ] = useState(false);
  const hasAccess = permissions.includes("SSP_AM_DATAMAN");

  const submitButtonLabel = hasAccess ? (
    isLoading ? (
      "Loading..."
    ) : (
      id ? "Edit" : "Add"
    )
  ) : (
    "No Access"
  );

  const buttons = [
    { name: "Cancel", disabled: isLoading, onClick: () => handleClose() },
    { name: submitButtonLabel, type: "submit", styleSet: "success", disabled: isLoading || !hasAccess }
  ];

  const offices = useOffices({ show });
  const travelAvailabilities = useTravelAvailabilities({ show });
  const responsables = useResponsables({ show });

  const handleClose = () => {
    if (isLoading) return;
    setData(defaultData);
    session.delParam("resources-form");
    onClose();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (id) {
      const res = await req("update-resource", { ...data });

      if (res.success) {
        setTableData(curr => ({ ...curr, data: curr.data.map(item => item.id === res.data.id ? res.data : item) }));
        notify("Success", "Resource has been updated", "success");
        handleClose();
      } else {
        if (res.error === 'access-denied') {
          notify("Error", "You don't have permission to do this action", 'error');
        } else if (res.error === 'already-exists') {
          notify("Warning", "Resource name already exists", 'warn');
        } else {
          notify("Error", "Please contact the administrator", 'error');
        }
      }
    } else {
      const res = await req("insert-resource", { ...data });

      if (res.success) {
        setTableData(curr => curr.data ? { ...curr, data: [ ...curr.data, res.data ] } : { ...curr, data: [ res.data ] });
        notify("Success", "Resource has been inserted", "success");
        handleClose();
      } else {
        if (res.error === 'access-denied') {
          notify("Error", "You do not have the necessary permissions", 'error');
        } else if (res.error === 'already-exists') {
          notify("Warning", "Resource name already exists", 'warn');
        } else {
          notify("Error", "Please contact the administrator", 'error');
        }
      }
    }

    setIsLoading(false);
  };

  const handleDataChange = (key, value) => {
    setData(curr => {
      const newData = { ...curr, [ key ]: value };
      session.addParam("resources-form", newData);
      return newData;
    });
  };

  useEffect(() => {
    const fetchResource = async () => {
      setIsLoading(true);

      const res = await req("select-resources-raw", { id: id });

      if (res.success) {
        setData(res.data[ 0 ]);
        setIsLoading(false);
      } else {
        notify("Error", "Please contact the administrator", 'error');
        handleClose();
      }

      setIsLoading(false);
    };

    if (id && show) fetchResource();
  }, [ id ]);

  useEffect(() => {
    if (data.office === "TEMP") {
      setData(curr => ({ ...curr, responsable: "" }));
    }
  }, [ data.office ]);

  return (
    <Modality show={ show } onClose={ handleClose } onSubmit={ handleSubmit } width="320px" buttons={ buttons }>
      { /* TITLE */ }
      <div className="text-xl">Resource</div>

      { /* SURNAME NAME */ }
      <div className="flex flex-col w-full">
        <label htmlFor="fullname">Surname Name</label>
        <input
          required={ true }
          className={ FormifyInputStyle }
          disabled={ isLoading || !hasAccess }
          value={ data.fullname }
          onInput={ (e) => handleDataChange("fullname", e.target.value) }
        />
      </div>

      { /* OFFICE */ }
      <div className="flex flex-col w-full">
        <label htmlFor="office">Office</label>
        <FormifyCombobox
          required={ true }
          className={ FormifyComboboxStyle }
          disabled={ isLoading || !hasAccess }
          value={ data.office }
          options={ offices }
          onChange={ (val) => handleDataChange("office", val) }
        >
          { offices.map(({ value, label }) => (
            <FormifyComboboxOption
              className={ clsx(
                "w-full h-full flex py-1 space-x-2 hover:space-x-0 overflow-hidden items-center data-[focus]:bg-gray-200 cursor-pointer",
                "hover:items-start hover:flex-col duration-75 [&:hover>.label]:text-wrap [&:hover>.hashtags]:flex-wrap overflow-hidden text-ellipsis",
                value === data.office ? "bg-green-200" : "hover:bg-gray-200 data-[focus]:bg-gray-200"

              ) }
              key={ value }
              value={ value }
              label={ label }
            >
              <div className="flex space-x-2 label  duration-75 text-nowrap">
                <Flaticon name="check" type="rr" size={ 18 } className={ clsx("text-green-600", value === data.office ? "visible" : "invisible") } />
                <div className="label">{ label }</div>
              </div>
            </FormifyComboboxOption>
          )) }
        </FormifyCombobox>
      </div>

      { /* responsable */ }
      <div className="flex flex-col w-full">
        <label htmlFor="responsable">Responsable</label>
        { data.office === "TM" ? (
          <input
            required={ true }
            className={ FormifyInputStyle }
            disabled={ isLoading || !hasAccess }
            value={ data.responsable }
            onInput={ (e) => handleDataChange("responsable", e.target.value) }
          />
        ) : (
          <FormifyCombobox
            required={ true }
            className={ FormifyComboboxStyle }
            disabled={ isLoading || !hasAccess }
            value={ data.responsable }
            options={ responsables }
            onChange={ (val) => handleDataChange("responsable", val) }
          >
            { responsables.map(({ value, label }) => (
              <FormifyComboboxOption
                className={ clsx(
                  "w-full h-full flex py-1 space-x-2 hover:space-x-0 overflow-hidden items-center data-[focus]:bg-gray-200 cursor-pointer",
                  "hover:items-start hover:flex-col duration-75 [&:hover>.label]:text-wrap [&:hover>.hashtags]:flex-wrap overflow-hidden text-ellipsis",
                  value === data.responsable ? "bg-green-200" : "hover:bg-gray-200 data-[focus]:bg-gray-200"
                ) }
                key={ value }
                value={ value }
                label={ label }
              >
                <div className="flex space-x-2 label  duration-75 text-nowrap">
                  <Flaticon name="check" type="rr" size={ 18 } className={ clsx("text-green-600", value === data.responsable ? "visible" : "invisible") } />
                  <div className="label">{ label }</div>
                </div>
              </FormifyComboboxOption>
            )) }
          </FormifyCombobox>
        ) }
      </div>

      { /* TRAVEL AVAILABILITY */ }
      <div className="flex flex-col w-full">
        <label htmlFor="travelAvailability">Travel Availability</label>
        <FormifyCombobox
          name="travelAvailability"
          className={ FormifyComboboxStyle }
          required={ true }
          disabled={ isLoading || !hasAccess }
          value={ data.travelAvailability }
          options={ travelAvailabilities }
          onChange={ (val) => handleDataChange("travelAvailability", val) }
        >

          { travelAvailabilities.map(({ value, label }) => (
            <FormifyComboboxOption
              className={ clsx(
                "w-full h-full flex py-1 space-x-2 hover:space-x-0 overflow-hidden items-center data-[focus]:bg-gray-200 cursor-pointer",
                "hover:items-start hover:flex-col duration-75 [&:hover>.label]:text-wrap [&:hover>.hashtags]:flex-wrap overflow-hidden text-ellipsis",
                value === data.travelAvailability ? "bg-green-200" : "hover:bg-gray-200 data-[focus]:bg-gray-200"
              ) }
              key={ value }
              value={ value }
              label={ label }
            >
              <div className="flex space-x-2 label  duration-75 text-nowrap">
                <Flaticon name="check" type="rr" size={ 18 } className={ clsx("text-green-600", value === data.travelAvailability ? "visible" : "invisible") } />
                <div className="label">{ label }</div>
              </div>
            </FormifyComboboxOption>
          )) }
        </FormifyCombobox>
      </div>

    </Modality>
  );
};

export default ResourceModal;