import { Transition, TransitionChild } from "@headlessui/react";
import { useEffect, useState } from "react";
import DashboardIcon from "../assets/icons/dashboard.png";
import DataManagementIcon from "../assets/icons/data-management.png";
import SchedulerIcon from "../assets/icons/scheduler.png";
import req from "../modules/Request";
import * as session from "../modules/Stash/Stash";
import { clsx } from "../modules/Utilkit/Utilkit";
import Dashboard from "./apps/dashboard";
import DataManagement from "./apps/data-management";
import Devices from "./apps/devices";
import Scheduler from "./apps/scheduler";
import Auth, { useAuth } from "./components/Auth";
import ContextMenu from "./components/ContextMenu";
import FloatPanel from "./components/FloatPanel";
import Navbar, { useNavigator } from "./components/Navigator";
import Notify, { useNotifyContext } from "./components/Notify";
import { useTitleBarTracker } from "./components/TitleBarTracker";

import config from "../config";

export default function App() {
	const {notify} = useNotifyContext();

	const nav = [
		{ id: 'dashboard', name: 'Dashboard', image: DashboardIcon },
		{ id: 'data-management', name: 'Management', image: DataManagementIcon },
		{ id: 'scheduler', name: 'Scheduler', image: SchedulerIcon }
	];

	const [ notificationStatus, setNotificationStatus ] = useState(false);

	useEffect(() => {
		req('get-notification-status').then(res => res.success && setNotificationStatus(res.data.status ?? false));
	}, []);

	const handleNotificationSwitch = () => {
		if (notificationStatus) {
			req('disable-notifications');
			setNotificationStatus(false);
		} else {
			if ('serviceWorker' in navigator) {
				// CHECK IF SERVICE WORKER IS ALREADY REGISTERED
				navigator.serviceWorker.getRegistrations().then((registrations) => {
					if (registrations.length === 0) {
						notify('Notifications denied', 'Service worker is not registered', 'error');
						console.error('Service worker is not registered');
						return;
					} else {
						console.log('Service worker registrations:', registrations);
					}

					Notification.requestPermission().then((perm) => {
						if (perm === 'granted') {
							req('enable-notifications');
						} else {
							notify('Notifications denied', 'You denied the notification permission', 'error');
							console.error('Notification permission denied');
						}
					});

					navigator.serviceWorker.ready.then((reg) => {
						if (!reg.pushManager) {
							notify('Notifications denied', 'Cannot enable notifications', 'error');
							console.error('Push manager is not available');
							return;
						}

						reg.pushManager.subscribe({
							userVisibleOnly: true,
							applicationServerKey: config.vapid.publicKey
						}).then((subscription) => {
							if (subscription) {
								console.log('Subscription successful:', JSON.stringify(subscription));
								req('register-service-worker', { subscription });

								notify('Notifications enabled', 'Now you will receive notifications', 'success');
								setNotificationStatus(true);
							} else {
								console.error('Subscription failed');
								notify('Notifications denied', 'Cannot subscribe to notifications', 'error');
							}
						}).catch((error) => {
							console.error('Subscription error:', error);
							notify('Notifications denied', 'Subscription error', 'error');
						});
					}).catch((error) => {
						console.error('Service worker ready error:', error);
						notify('Notifications denied', 'Service worker ready error', 'error');
					});
				}).catch((error) => {
					console.error('Service worker registration error:', error);
					notify('Notifications denied', 'Service worker registration error', 'error');
				});
			} else {
				notify('Notifications denied', 'Your browser does not support notifications', 'error');
				console.error('Service worker is not supported by this browser');
			}
		}
	}
	
	const handleSwitchDarkMode = () => {
		const darkMode = document.documentElement.classList.contains('dark');
		document.documentElement.classList.toggle('dark', !darkMode);
		session.addStore('dark-mode', !darkMode);
	}

	const usernav = [
		{ id: 'notifications', name: (notificationStatus ? 'Disable' : 'Enable') + ' Notification', style: 'text-yellow-600', onClick: handleNotificationSwitch, icon: { name: notificationStatus ? 'bell' : 'bell-slash', type: 'rr' } },
		{ id: 'devices', name: 'Devices', style: 'text-blue-600', onClick: () => go('profile'), icon: { name: 'devices', type: 'rr' } },
		{ id: 'logout', name: 'Log Out', style: 'text-red-600', className: "text-red-400", icon: { name: 'sign-out-alt', type: 'rr' } },
	];

	const { isOverlayVisible } = useTitleBarTracker();
	const { authStatus } = useAuth();
	const { go } = useNavigator();

	return (
		<div className="relative flex justify-center items-center w-full h-full">
			{ authStatus === "sign-in-required" && isOverlayVisible && (
				<div className="fixed w-full [-webkit-app-region:drag] h-8 top-0 left-0 z-50" />
			) }
			<Notify />
			<FloatPanel />
			<ContextMenu />

			<div className="flex justify-center items-center w-full h-full absolute">
				<Auth />
			</div>

			<Transition
				show={ authStatus === 'success' }
				as="nav"
				className="fixed top-0 left-0 z-30 flex justify-start items-center px-1 bg-[#0050a3] h-[env(titlebar-area-height,33px)] w-full duration-150"
				enter="transition duration-500"
				enterFrom="opacity-0"
				enterTo="opacity-100"
				leave="transition duration-500"
				leaveFrom="opacity-100"
				leaveTo="opacity-0"
			>
				<Navbar
					nav={ nav }
					usernav={ usernav }
				/>
			</Transition>

			<Transition
				show={ authStatus === 'success' }
				as="main"
				className={ clsx(
					"absolute flex justify-center items-center w-full h-full bg-white",
					authStatus === 'success' ? "z-20" : "z-[0!important]"
				) }
				enter="transform duration-700 z-[0!important] transition-[width,height,border-radius]"
				enterFrom="rounded-3xl w-[400px!important] h-[400px!important]"
				enterTo="rounded-0 w-full h-full"
				leave="transform duration-700 z-[0!important] transition-[width,height,border-radius]"
				leaveFrom="rounded-0 w-full h-full"
				leaveTo="rounded-3xl w-[400px!important] h-[400px!important]"
			>
				<TransitionChild
					as="div"
					className="w-full h-full"
					enter="transition duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="transition duration-300"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<PageTransition page="dashboard">
						<Dashboard />
					</PageTransition>

					<PageTransition page="data-management">
						<DataManagement />
					</PageTransition>

					<PageTransition page="scheduler">
						<Scheduler />
					</PageTransition>

					<PageTransition page="profile">
						<Devices />
					</PageTransition>
				</TransitionChild>
			</Transition>
		</div>
	);
};

const PageTransition = ({ page, children }) => {
	const { active } = useNavigator();

	return (
		<Transition
			show={ active === page }
			as="div"
			className={ clsx("w-full h-full pt-[env(titlebar-area-height,33px)]", active !== page && "absolute") }
			enter="transition duration-300"
			enterFrom="opacity-0"
			enterTo="opacity-100"
			leave="transition duration-300 absolute"
			leaveFrom="opacity-100"
			leaveTo="opacity-0"
		>
			{ children }
		</Transition>
	);

};